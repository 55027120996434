import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGavel, faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import './MyBids.css';
import { useMediaQuery } from '@mui/material';

const MyBids = () => {
  const isMobileScreen = useMediaQuery("(max-width:430px)");
  return (
    <div className="my-bids-dashboard-container">
      <div className="my-bids-dashboard-header">
        <h2 className="my-bids-dashboard-title">My Bids</h2>
        <a href="/history" className="my-bids-dashboard-history-link">View History</a>
      </div>
      <div className="my-bids-dashboard-grid">
        {/* Bidding Section */}
        <div className="my-bids-dashboard-card">
          <div className="my-bids-dashboard-card-header">
            <h3>
              <FontAwesomeIcon icon={faGavel} />
              Bidding
              <span className="my-bids-dashboard-badge">0</span>
            </h3>
            <a href="#" className="my-bids-dashboard-view-all">View All</a>
          </div>
          <div className="my-bids-dashboard-stat-grid">
            <div className="my-bids-dashboard-stat-item">
              <div className="my-bids-dashboard-icon winning">
                <FontAwesomeIcon icon={faCheckCircle} />
              </div>
              <span>Winning</span>
              <span className="my-bids-dashboard-number">1</span>
            </div>
            <div className="my-bids-dashboard-stat-item">
              <div className="my-bids-dashboard-icon outbid">
                <FontAwesomeIcon icon={faTimesCircle} />
              </div>
              <span>Outbid</span>
              <span className="my-bids-dashboard-number">0</span>
            </div>
            <div className="my-bids-dashboard-stat-item" style={{border:"none"}}>
              <div className="my-bids-dashboard-icon completed">
                <FontAwesomeIcon icon={faCheckCircle} />
              </div>
              <span>Completed</span>
              <span className="my-bids-dashboard-number">0</span>
            </div>
          </div>
        </div>

        {/* Pre-Auction Offers */}
        <div className="my-bids-dashboard-card">
          <div className="my-bids-dashboard-card-header">
            <h3>
              <FontAwesomeIcon icon={faGavel} />
              Pre-Auction Offers
              <span className="my-bids-dashboard-badge">0</span>
            </h3>
            <a href="#" className="my-bids-dashboard-view-all">View All</a>
          </div>
          <div className="my-bids-dashboard-stat-grid" style={{flexWrap:"wrap"}}>
            <div className="my-bids-dashboard-stat-box">
              <span>Submitted</span>
              <span className="my-bids-dashboard-number">0</span>
            </div>
            <div className="my-bids-dashboard-stat-box">
              <span>Withdrawn</span>
              <span className="my-bids-dashboard-number">0</span>
            </div>
            <div className="my-bids-dashboard-stat-box" style={{borderRight:isMobileScreen?"none":''}}>
              <span>Approved</span>
              <span className="my-bids-dashboard-number">0</span>
            </div>
            <div className="my-bids-dashboard-stat-box" style={{borderRight:'none',borderTop:isMobileScreen?'1px solid #ddd':"none"}}>
              <span>Rejected</span>
              <span className="my-bids-dashboard-number">0</span>
            </div>
          </div>
        </div>

        {/* Contracts Section */}
        <div className="my-bids-dashboard-card">
          <div className="my-bids-dashboard-card-header">
            <h3>
              <FontAwesomeIcon icon={faGavel} />
              Contracts
              <span className="my-bids-dashboard-badge">0</span>
            </h3>
            <a href="#" className="my-bids-dashboard-view-all">View All</a>
          </div>
          <div className="my-bids-dashboard-contracts-grid">
            <div className="my-bids-dashboard-contract-row">
              <span>Pending EC Form</span>
              <span className="my-bids-dashboard-number">0</span>
            </div>
            <div className="my-bids-dashboard-contract-row">
              <span>Start Contract</span>
              <span className="my-bids-dashboard-number">0</span>
            </div>
            <div className="my-bids-dashboard-contract-row">
              <span>In Progress</span>
              <span className="my-bids-dashboard-number">0</span>
            </div>
            <div className="my-bids-dashboard-contract-row">
              <span>Cancelled</span>
              <span className="my-bids-dashboard-number">0</span>
            </div>
            <div className="my-bids-dashboard-contract-row">
              <span>Contract Executed</span>
              <span className="my-bids-dashboard-number">0</span>
            </div>
          </div>
        </div>

        {/* Favorites */}
        <div className="my-bids-dashboard-card">
          <div className="my-bids-dashboard-card-header">
            <h3>
              <FontAwesomeIcon icon={faGavel} />
              Favorites
            </h3>
          </div>
          <div className="my-bids-dashboard-no-favorites">
            You have not saved any property as a favorite.
            <a href="#" className="my-bids-dashboard-click-here">Click Here</a> to search and favorite Auction properties.
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyBids;
