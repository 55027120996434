import React from 'react';
import './PropertyCard.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart as regularHeart } from '@fortawesome/free-regular-svg-icons';
import { faBath, faBedPulse, faHouse, faHeart as Soliddheart } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setUser } from '../../../features/user/userSlice';
import { useLocation } from 'react-router-dom';
import SaveListingModal from '../../ListingModal/SaveListingModal';
const PropertyCard = ({ product, width, boxShadow, flexDirection, height }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false)
  if (!product) {
    return null;
  }
  const handleViewClick = () => {
    window.location.href = `/Property-detail/${product._id}`;
  }
  const handlePrevClick = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? product.otherImages.length - 1 : prevIndex - 1
    );
  };

  // Handle next arrow click
  const handleNextClick = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === product.otherImages.length - 1 ? 0 : prevIndex + 1
    );
  };
  const user = useSelector((state) => state.user.user);
  const [isSaved, setIsSaved] = useState(true);
  useEffect(() => {
    if (user && user.savedProperties.length > 0 && user.savedProperties.find(productObject => productObject._id === product._id)) {
      setIsSaved(true);
    }
    else {
      setIsSaved(false);
    }
  }, [])
  const calculateTimeLeft = () => {
    const difference = +new Date(product.auctionEndDate) - +new Date();
    let timeLeft = {};
    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
      };
    }
    return timeLeft;
  };
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 100); // Update every minute
    return () => clearTimeout(timer);
  });
  const handleHeartClick = async (event) => {
    event.stopPropagation();
    if (!user) {
      handleOpen();
      return;
    }
    const text = isSaved ? "rempve" : "save";
    const toasttext = isSaved ? "remove" : "save";
    try {
      console.log("hi")
      const response = await axios.put(`https://viharabackend.onrender.com/api/v1/user/${text}-property`, {
        user_id: user._id,
        product_id: product._id
      });
      dispatch(setUser(response.data.user));
      if (toasttext === "remove" && location.pathname !== "/saved-updates") {
        setIsSaved(false);
      }
      if (toasttext === "save") {
        setIsSaved(true);
      }
      toast.success(`Property ${toasttext} Successfully`);
    } catch (error) {
      console.log(error);
    }
  }
  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { month: 'long', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  }
  const decidePropertyType = (product) => {
    if (product.propertyType === "Foreclosure Sale") return "Foreclosure"
    if (product.propertyType === "Short Sale") return "Short Sale"
    else return "Bank Owned"
  }
  return (
    <>
      <SaveListingModal open={open} onClose={handleClose} />
      <div style={{ width: width ? width : "", boxShadow: boxShadow ? boxShadow : "", flexDirection: flexDirection, height: height ? height : "" }} className="property-card" >
        <div className="property-card-image">
          <div className="property-card-carousel-container">
            <img
              src={product.otherImages[currentImageIndex]}
              alt={`Slide ${currentImageIndex}`}
              className="proerty-card-carousel-image"
            />

            {/* Carousel Arrows */}
            <button className="property-card-carousel-arrow left-arrow" onClick={handlePrevClick}>&#10094;</button>
            <button className="property-card-carousel-arrow right-arrow" onClick={handleNextClick}> &#10095;</button>
          </div>
          <div className="property-card-overlay">
            <div className="property-overlay-text"><span>Hot</span><span>FCL Predict</span></div>
            <FontAwesomeIcon icon={isSaved ? Soliddheart : regularHeart} className="property-icon-right"
              style={{ color: "white", zIndex: "1" }}
              size='lg'
              onClick={(event) => handleHeartClick(event)}
            />
          </div>
          <div className='property-number-of-images'>
              {currentImageIndex+1}/{product.otherImages.length}
          </div>
          <div className='property-type-of-property'>
            <span className='property-card-image-circle' style={{ backgroundColor: decidePropertyType(product) === "Foreclosure" ? "#8FB035" : decidePropertyType(product) === "Bank Owned" ? "#f2a23b" : "#51a5db" }} ></span> <span>{decidePropertyType(product)}</span>
          </div>
        </div>
        <div className="propert-card-content">
          <div className='property-card-address'>{product.street}</div>
          <div className='property-card-address'>{product.city}, {product.state}</div>
          <div><span className='property-card-value' ><FontAwesomeIcon className='property-card-icon' icon={faBedPulse} /> {product.beds}  beds </span> <span className='property-card-value' > <FontAwesomeIcon className='property-card-icon' icon={faBath} /> {product.baths} baths</span> <span className='property-card-value' > <FontAwesomeIcon className='property-card-icon' icon={faHouse} /> {product.squareFootage} ft<sup>2</sup></span></div>
          <div className="property-card-property-type"><img className='foreclosure-image' src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNzIiIGhlaWdodD0iNzIiIHZpZXdCb3g9IjAgMCA3MiA3MiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgZmlsdGVyPSJ1cmwoI2ZpbHRlcjBfZF8yMDIyXzE3MTU3KSI+CjxjaXJjbGUgY3g9IjM2IiBjeT0iMzIiIHI9IjI0IiBmaWxsPSJ3aGl0ZSIvPgo8L2c+CjxjaXJjbGUgY3g9IjM2IiBjeT0iMzIiIHI9IjE4IiBmaWxsPSIjMDM4NGZiIi8+CjxkZWZzPgo8ZmlsdGVyIGlkPSJmaWx0ZXIwX2RfMjAyMl8xNzE1NyIgeD0iMCIgeT0iMCIgd2lkdGg9IjcyIiBoZWlnaHQ9IjcyIiBmaWx0ZXJVbml0cz0idXNlclNwYWNlT25Vc2UiIGNvbG9yLWludGVycG9sYXRpb24tZmlsdGVycz0ic1JHQiI+CjxmZUZsb29kIGZsb29kLW9wYWNpdHk9IjAiIHJlc3VsdD0iQmFja2dyb3VuZEltYWdlRml4Ii8+CjxmZUNvbG9yTWF0cml4IGluPSJTb3VyY2VBbHBoYSIgdHlwZT0ibWF0cml4IiB2YWx1ZXM9IjAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDEyNyAwIiByZXN1bHQ9ImhhcmRBbHBoYSIvPgo8ZmVPZmZzZXQgZHk9IjQiLz4KPGZlR2F1c3NpYW5CbHVyIHN0ZERldmlhdGlvbj0iNiIvPgo8ZmVDb2xvck1hdHJpeCB0eXBlPSJtYXRyaXgiIHZhbHVlcz0iMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMC4zIDAiLz4KPGZlQmxlbmQgbW9kZT0ibm9ybWFsIiBpbjI9IkJhY2tncm91bmRJbWFnZUZpeCIgcmVzdWx0PSJlZmZlY3QxX2Ryb3BTaGFkb3dfMjAyMl8xNzE1NyIvPgo8ZmVCbGVuZCBtb2RlPSJub3JtYWwiIGluPSJTb3VyY2VHcmFwaGljIiBpbjI9ImVmZmVjdDFfZHJvcFNoYWRvd18yMDIyXzE3MTU3IiByZXN1bHQ9InNoYXBlIi8+CjwvZmlsdGVyPgo8L2RlZnM+Cjwvc3ZnPgo=" alt="Updated SVG Image" />
            {product.propertyType}, {product.onlineOrInPerson}, {formatDate(product.auctionStartDate)}</div>
          <div className="countdown-timer">
            <FontAwesomeIcon icon={faClock} className='clock-timer' />
            {timeLeft.days !== undefined && (
              <>
                <span className='time-left-container'> <span className='time-left'>{timeLeft.days} </span><span>Days</span> </span><span className='two-dots-property-card'>:</span>
                <span className='time-left-container'><span className='time-left'> {timeLeft.hours} </span> <span>Hours</span>  </span><span className='two-dots-property-card' >:</span>
                <span className='time-left-container'><span className='time-left'>{timeLeft.minutes}</span> <span>Minutes</span> </span>
              </>
            )}
            {timeLeft.days === undefined && <span className='auction-ended'>Auction Ended</span>}
          </div>
          <div className='property-card-horizontal-line'></div>
          <div><span className='starting-bid-text'><span>Starting Bid:</span> <span className='property-card-starting-bid'>${product.startBid.toLocaleString('en-US')}</span></span>  <button onClick={handleViewClick} className='property-card-view-now-button'>View Details</button></div>
        </div>
      </div>
    </>
  );
};

PropertyCard.propTypes = {
  product: PropTypes.object
};

export default PropertyCard;
