import "./BidConfirmationModal.css"
import { Dialog, DialogContent} from "@mui/material";
import {faClock} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const BidConfirmationModal = ({ 
  isOpen, 
  onClose, 
  currentBid,
  bidIncrement,
  proposedBid,
  buyersPremium,
  timeRemaining,
  onPlaceBid,
  isHighestBidder = false
}) => {
  const totalPurchasePrice = proposedBid + buyersPremium;
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <div className="bid-confirmation-modal-overlay">
        <div className="bid-confirmation-modal-container">
          {isHighestBidder ? (
            <div className="bid-confirmation-modal-header-success">
              <h2 className="bid-confirmation-modal-title">
                Congratulations! You are the highest bidder.
              </h2>
              <button 
                onClick={onClose}
                className="bid-confirmation-modal-close-button"
              >
                ×
              </button>
            </div>
          ) : (
            <div className="bid-confirmation-modal-header">
              <h2 className="bid-confirmation-modal-title">
                Bid Confirmation
              </h2>
              <button 
                onClick={onClose}
                className="bid-confirmation-modal-close-button"
              >
                ×
              </button>
            </div>
          )}

          <div className="bid-confirmation-modal-content">
            <div className="bid-confirmation-modal-timer">
              <div className="bid-confirmation-modal-timer-header">
              <FontAwesomeIcon icon={faClock} className="bid-confirmation-modal-timer-icon" />
                <span className="bid-confirmation-modal-timer-label">Time Remaining</span>
              </div>
              <div className="bid-confirmation-modal-timer-digits">
                <span>{timeRemaining.days}</span>
                <span>:</span>
                <span>{String(timeRemaining.hours).padStart(2, '0')}</span>
                <span>:</span>
                <span>{String(timeRemaining.minutes).padStart(2, '0')}</span>
              </div>
            </div>

            <div className="bid-confirmation-modal-details">
              <div className="bid-confirmation-modal-detail-row">
                <span className="bid-confirmation-modal-detail-label">Current Bid</span>
                <span className="bid-confirmation-modal-detail-value">
                  ${currentBid.toLocaleString()}
                </span>
              </div>
              <div className="bid-confirmation-modal-detail-row">
                <span className="bid-confirmation-modal-detail-label">Bid Increment</span>
                <span className="bid-confirmation-modal-detail-value">
                  ${bidIncrement.toLocaleString()}
                </span>
              </div>
              <div className="bid-confirmation-modal-detail-row bid-confirmation-modal-detail-row-highlighted">
                <span>Your Bid</span>
                <span>${proposedBid.toLocaleString()}</span>
              </div>
              <div className="bid-confirmation-modal-detail-row">
                <span className="bid-confirmation-modal-detail-label">Buyer's Premium</span>
                <span className="bid-confirmation-modal-detail-value">
                  ${buyersPremium.toLocaleString()}
                </span>
              </div>
              <div className="bid-confirmation-modal-detail-row bid-confirmation-modal-total-row">
                <span>Total Purchase Price</span>
                <span>${totalPurchasePrice.toLocaleString()}</span>
              </div>
            </div>
          </div>

          <div className="bid-confirmation-modal-footer">
            <button
              onClick={isHighestBidder ? onClose : onPlaceBid}
              className="bid-confirmation-modal-submit-button"
            >
              {isHighestBidder ? 'OK' : 'PLACE BID'}
            </button>
          </div>

          <div className="bid-confirmation-modal-help">
            <button className="bid-confirmation-modal-help-link">
              If I am the Winning Bidder, what are the next steps?
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
export default BidConfirmationModal;