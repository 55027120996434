// ContractStep.jsx
import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

const ContractStep = ({ 
  formData, 
  handleInputChange, 
  product, 
  currentBid, 
  buyersPremium 
}) => {
  const totalPurchasePrice = currentBid + buyersPremium;
  const earnestMoney = totalPurchasePrice * 0.05; // 5% earnest money

  return (
    <div className="submit-checkout-form-step-content">
      <div className="submit-checkout-form-section">
        <div className="submit-checkout-form-section-title">CONTRACT CONFIRMATION</div>

        {/* Important Notice */}
        <div className="submit-checkout-form-contract-notice">
          <FontAwesomeIcon icon={faExclamationTriangle} className="submit-checkout-form-warning-icon" />
          <h4>Important Notice</h4>
          <p>
            By proceeding with this submission, you are entering into a legally binding agreement 
            to purchase this property. Please review all information carefully before proceeding.
          </p>
        </div>

        {/* Purchase Agreement Summary */}
        <div className="submit-checkout-form-contract-summary">
          <h3>Purchase Agreement Summary</h3>
          <div className="submit-checkout-form-contract-details">
            <div className="submit-checkout-form-contract-item">
              <span>Property Address: </span>
              <span>{product?.state}, {product?.city}, {product?.street}</span>
            </div>
            <div className="submit-checkout-form-contract-item">
              <span>Purchase Price: </span>
              <span>${currentBid.toLocaleString()}</span>
            </div>
            <div className="submit-checkout-form-contract-item">
              <span>Buyer's Premium: </span>
              <span>${buyersPremium.toLocaleString()}</span>
            </div>
            <div className="submit-checkout-form-contract-item">
              <span>Total Purchase Price: </span>
              <span>${totalPurchasePrice.toLocaleString()}</span>
            </div>
            <div className="submit-checkout-form-contract-item">
              <span>Required Earnest Money: </span>
              <span>${earnestMoney.toLocaleString()}</span>
            </div>
          </div>
        </div>

        {/* Contract Terms */}
        <div className="submit-checkout-form-contract-terms">
          <h3>Terms & Deadlines</h3>
          <ul>
            <li>Earnest Money Deposit must be submitted within 24 hours of bid acceptance</li>
            <li>Proof of funds or pre-approval letter required within 48 hours</li>
            <li>Property inspection period: 10 calendar days from acceptance</li>
            <li>Closing must occur within 30 calendar days</li>
            <li>Failure to meet deadlines may result in forfeiture of earnest money</li>
          </ul>
        </div>

        {/* Final Agreements */}
        <div className="submit-checkout-form-contract-agreements">
          <div className="submit-checkout-form-checkbox-group">
            <label className="submit-checkout-form-checkbox-label">
              <input
                type="checkbox"
                checked={formData.agreeToContract}
                onChange={(e) => handleInputChange('agreeToContract', e.target.checked)}
                className="submit-checkout-form-checkbox"
              />
              <span>
                I have read and agree to the Purchase Agreement terms and conditions
              </span>
            </label>
          </div>

          <div className="submit-checkout-form-checkbox-group">
            <label className="submit-checkout-form-checkbox-label">
              <input
                type="checkbox"
                checked={formData.agreeToDeposit}
                onChange={(e) => handleInputChange('agreeToDeposit', e.target.checked)}
                className="submit-checkout-form-checkbox"
              />
              <span>
                I agree to submit the required earnest money deposit of ${earnestMoney.toLocaleString()}{" "}
                 within 24 hours of bid acceptance
              </span>
            </label>
          </div>

          <div className="submit-checkout-form-checkbox-group">
            <label className="submit-checkout-form-checkbox-label">
              <input
                type="checkbox"
                checked={formData.acceptTerms}
                onChange={(e) => handleInputChange('acceptTerms', e.target.checked)}
                className="submit-checkout-form-checkbox"
              />
              <span>
                I understand that this is a legally binding agreement and accept all terms and conditions
              </span>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContractStep;