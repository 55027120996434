import React, { useState } from 'react';
import './UserPortal.css'; // Assuming you will style it accordingly
import UserProfile from './UserProfile/UserProfile';
import MyBids from './MyBids/MyBids';
import SavedSearches from './SavedSearches/SavedSearches';
import MyPurchaseProfiles from './MyPurchaseProfiles/MyPurchaseProfiles';
import MyOffers from './MyOffers/MyOffers';
import Favorites from './Favorites/Favorites';
import AccountSettings from './AccountSettings/AccountSettings';
import NavBackground from '../includes/navBackground';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
const UserPortal = () => {
  const { tab } = useParams();  
  const [activeTab, setActiveTab] = useState(tab);
  const renderContent = () => {
    switch (activeTab) {
      case 'profile':
        return <UserProfile />;
      case 'bids':
        return <MyBids />;
      case 'offers':
        return <MyOffers />;
      case 'savedSearches':
        return <SavedSearches />;
      case 'favorites':
        return <Favorites />;
      case 'settings':
        return <AccountSettings />;
      default:
        return <UserProfile />;
    }
  };

  return (
    <>
     <NavBackground/>
    <div className="user-portal-container">
      <div className="user-portal-sidebar">
        <ul className="user-portal-menu">
          <li onClick={() => setActiveTab('profile')} className={activeTab === 'profile' ? 'active' : ''}>
            My Profile
          </li>
          <li onClick={() => setActiveTab('bids')} className={activeTab === 'bids' ? 'active' : ''}>
            My Bids
          </li>
          {/* <li onClick={() => setActiveTab('purchase')} className={activeTab === 'purchase' ? 'active' : ''}>
            My Purchase Profiles
          </li> */}
          <li onClick={() => setActiveTab('offers')} className={activeTab === 'offers' ? 'active' : ''}>
            My Offers
          </li>
          <li onClick={() => setActiveTab('savedSearches')} className={activeTab === 'savedSearches' ? 'active' : ''}>
            Saved Searches
          </li>
          <li onClick={() => setActiveTab('favorites')} className={activeTab === 'favorites' ? 'active' : ''}>
            Favorites
          </li>
          <li  onClick={() => setActiveTab('settings')} className={activeTab === 'settings' ? 'active' : ''}>
            Account Settings
          </li>
        </ul>
      </div>
      <div className="user-portal-content">
        {renderContent()}
      </div>
    </div>
    </>
  );
};
export default UserPortal;
