import React, { useState } from 'react';
import "./MakingBiddingEasier.css";
import biddingImage from "./assets/newBiddingImage.jpg";
import  homeImage from "./assets/home image (1).png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faAngleRight } from '@fortawesome/free-solid-svg-icons';

export const BiddingEasierImageSection =()=>{
    const [hoveredOnIcon, setHoveredOnIcon] = useState({ 1: false, 2: false, 3: false, 4: false, 5: false, 6: false, 7: false, 8: false });
    return (
        <div className="making-bidding-image-row">
                <div className='making-bidding-image-container'>
                    {/* <div className='making-bidding-overlay'></div> */}
                    <div>
                        <h2>How to Prepare for an Auction</h2>
                        {/* <p className='making-bidding-para'>Learn why property research is important before participating in a foreclosure property auction for assessing value, uncovering potential issues, and making informed decisions about bidding.</p> */}
                    </div>
                    <span onMouseOver={() => setHoveredOnIcon({ 5: true })} onMouseLeave={() => setHoveredOnIcon({ 5: false })}>
                        <a href="/prepearing_online_auction">Read the story <FontAwesomeIcon className='resources-my-icons' icon={hoveredOnIcon[5] ? faArrowRight : faAngleRight} /></a>
                    </span>
                    <img src={homeImage} alt="Property Research" className='making-bidding-my-image' />
                </div>
                <div className='making-bidding-image-container'>
                    {/* <div className='making-bidding-overlay'></div> */}
                    <div>
                        <h2>Remote Bidding</h2>
                        {/* <p>Learn how the remote bid function makes participating in auctions more convenient and accessible for you.</p> */}
                 </div>
                    <span onMouseOver={() => setHoveredOnIcon({ 6: true })} onMouseLeave={() => setHoveredOnIcon({ 6: false })}>
                        <a href="">Read the story <FontAwesomeIcon className='resources-my-icons' icon={hoveredOnIcon[6] ? faArrowRight : faAngleRight} /></a>
                    </span>
                    <img src={biddingImage} alt="Remote Bidding" className='making-bidding-my-image' />
                </div>
            </div>
    )
}

function MakingBiddingEasier() {
    

    return (
        <div className="making-bidding-easier-home">
            <h1>Making bidding easier for our customers.</h1>
            <p className='making-bidding-para'>From enhancing property visibility to optimizing transaction efficiency, discover how Vihara is transforming the operations of the world leading real estate organizations and elevating their service to communities.</p>
            <BiddingEasierImageSection/>
            {/* <button>Read More Customer Stories</button> */}
        </div>
    );
}

export default MakingBiddingEasier;
