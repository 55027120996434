import React from "react";
import "./Footer.css"; // Importing the footer.css file
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Images } from "../../../constants/constant";
const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-links">
          <div className="brand">
            <img
              className="logo-small"
              src={Images.logoOnlight}
              alt="Vihara Logo"
            />{" "}
            {/* Replace with your logo path */}
          </div>
          <div className="footer-section">
            <div className="footer-section-links">
              <strong>Auctions</strong>
              <ul>
                <li>
                  <a href="/blog"> REO Bank Owned </a>
                </li>
                <li>
                  <a href="/help">Short Sale</a>
                </li>{" "}
                {/* Update href with your URLs */}
                <li>
                  <a href="/buying-forclosure">Foreclosure Homes</a>
                </li>
                <li>
                  <a href="/event-calender">Auctions Calendar</a>
                </li>
              </ul>
            </div>
            <div className="footer-section-links">
              <strong>Resources</strong>
              <ul>
                {/* <li>
                  <a href="/blog">Blog</a>
                </li> */}
                <li>
                  <a href="/faqs">FAQs</a>
                </li>{" "}
                {/* Update href with your URLs */}
                <li>
                  <a href="/buying-forclosure">Buying a Foreclosure</a>
                </li>
                <li>
                  <a href="/buying-bank-owned">Buying a Bank Owned</a>
                </li>
                <li>
                  <a href="/glossary">Glossary</a>
                </li>
                <li>
                  <a href="/contact-us">Contact</a>
                </li>
              </ul>
            </div>
            <div className="footer-section-links">
              <strong>Company</strong>
              <ul>
                <li>
                  <a href="/about-us">About</a>
                </li>
                <li>
                  <a href="/team">Meet the Team</a>
                </li>
                <li>
                  <a href="/career">Vihara Jobs</a>
                </li>
                <li>
                  <a href="/diversity">Diversity</a>
                </li>
                <li>
                  <a href="/Privacy">Privacy</a>
                </li>
                <li>
                  <a href="/privacy-statement">Privacy Statement</a>
                </li>
                <li className="footer-items-to-hide-larger-screen">
                <a href="/website-terms-of-use">Website Terms of Use</a>
                </li>
                <li className="footer-items-to-hide-larger-screen">
                <a href="/participation-terms">Participation Terms</a>
                </li>
                <li className="footer-items-to-hide-larger-screen">
                <a href="/accessibility">Accesibility Statement</a>
                </li>
              </ul>
            </div>
            <div   className="footer-section-links footer-items-to-hide-smaller-screen">
              {/* <strong style={{visibility:"hidden"}}>Website Terms of Use</strong> */}
              <ul style={{marginTop:"-8px"}}>
                <li>
                <a href="/website-terms-of-use">Website Terms of Use</a>
                </li>
                <li>
                <a href="/participation-terms">Participation Terms</a>
                </li>
                <li>
                <a href="/accessibility">Accesibility Statement</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="footer-icons">
          {/* <div className='select-country'></div> */}
          <div className="social-media-icons">
          <a href="https://www.facebook.com/profile.php?id=61556248807076" target="_blank"><FontAwesomeIcon icon={faFacebook} className="fa-lg" /></a>
           <a href="https://www.instagram.com/vihara.ai/" target="_blank"> <FontAwesomeIcon icon={faInstagram} className="fa-lg" /></a>
           {/* <a href="#"> <FontAwesomeIcon icon={faYoutube} className="fa-lg" /></a> */}
           <a href="https://www.linkedin.com/company/vihara-ai/" target="_blank"> <FontAwesomeIcon icon={faLinkedin} className="fa-lg" /></a>
          </div>
        </div>
        <div className="copy">
          <div className="terms-and-privacy">
            <div>
              <span>
                <a className="terms-and-privacy-link" href="/privacy-statement">
                  Privacy Statement
                </a>{" "}
                &nbsp;|&nbsp;{" "}
              </span>
              <span>
                {" "}
                <a
                  className="terms-and-privacy-link"
                  href="/website-terms-of-use"
                >
                  Website Terms of Use
                </a>
                &nbsp;|&nbsp;{" "}
              </span>
              <span>
                <a
                  className="terms-and-privacy-link"
                  href="/participation-terms"
                >
                  Participation Terms
                </a>
              </span>
            </div>
            <div>
              <span>
                {" "}
                <a className="terms-and-privacy-link" href="/accessibility">
                  Accessibility Statement
                </a>{" "}
                &nbsp;|&nbsp;{" "}
              </span>
              <span>
                <a className="terms-and-privacy-link" href="/privacy-choice">
                  Your Privacy Choices
                </a>
              </span>
            </div>
          </div>
          <div className="brand-2">
            <img
              className="logo-small-2"
              src={Images.logoOnlight}
              alt="Vihara Logo"
            />{" "}
            {/* Replace with your logo path */}
          </div>
          <div className="footer-copy-write-section">© 2024 RL Auction Inc.</div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
