import React, { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPrint,
  faBed,
  faBath,
  faRuler,
} from "@fortawesome/free-solid-svg-icons";
import "./PrintProperty.css";


const PrintProperty = ({ product }) => {
  // Create a reference to the DOM element we want to print
  const componentRef = useRef();

  // Function to handle the printing logic
  const handlePrint = () => {
    // Open a new browser window for the print view
    const printWindow = window.open("", "_blank", "width=800,height=600");

    if (printWindow) {
      // Write HTML content for the new window, including the styles and the content to print
      printWindow.document.write(`
        <html>
          <head>
            <title>Print Property</title>
            <style>
              /* Add CSS styles here for the print layout */

              .print-property-container {
                padding: 2rem;
                max-width: 64rem;
                margin: 0 auto;
              }

              .print-property-header {
                margin-bottom: 1.5rem;
                border-bottom: 1px solid #e5e7eb;
                padding-bottom: 1rem;
              }

              .print-property-title {
                font-size: 1.5rem;
                font-weight: 700;
                color: #1a1a1a;
              }

              .print-property-image {
                width: 100%;
                height: 16rem;
                object-fit: cover;
                border-radius: 0.5rem;
              }

              @media print {
                .print-property-button {
                  display: none; /* Hide the print button during printing */
                }
              }
            </style>
          </head>
          <body>
            ${componentRef.current.innerHTML} <!-- Get the HTML inside the ref -->
          </body>
        </html>
      `);

      // Close the document to finish writing
      printWindow.document.close();
      printWindow.focus(); // Focus on the print window
      printWindow.print(); // Trigger the print dialog
      // printWindow.close(); // Optionally close the print window after printing
    }
  };

  // If no product data is passed, return null (nothing is rendered)
  if (!product) {
    return null;
  }

  return (
    <div>
      {/* Print Button */}
      <button onClick={handlePrint} className="print-property-button">
        {/* FontAwesome print icon */}
        <FontAwesomeIcon icon={faPrint} className="print-property-button-icon" />
        Print
      </button>

      {/* Printable Content */}
      <div ref={componentRef} className="print-property-container">
        {/* Header Section */}
        <div className="print-property-header">
          <h1 className="print-property-title">
            {/* Display the property street or a fallback if unavailable */}
            {product.street || "Address Not Available"}
          </h1>
          <p className="print-property-address">
            {/* Combine city, state, and zip code */}
            {`${product.city || ""}, ${product.state || ""} ${product.zipCode || ""}`}
          </p>
        </div>

        {/* Image Section */}
        <div className="print-property-image-container">
          {product.image && (
            <img
              src={product.image}
              alt={product.productName || "Property Image"}
              className="print-property-image"
            />
          )}
        </div>

        {/* Property Details Section */}
        <div className="print-property-details-grid">
          {/* Features Section */}
          <div className="print-property-section">
            <h2 className="print-property-section-title">Property Features</h2>
            <div className="print-property-feature-list">
              <p className="print-property-feature-item">{product.beds || "N/A"} Beds</p>
              <p className="print-property-feature-item">{product.baths || "N/A"} Baths</p>
              <p className="print-property-feature-item">
                {product.squareFootage
                  ? Number(product.squareFootage).toLocaleString()
                  : "N/A"}{" "}
                Sq. Ft.
              </p>
              <p className="print-property-feature-item">
                Lot Size: {product.lotSize || "N/A"} Acres
              </p>
              <p className="print-property-feature-item">
                Year Built: {product.yearBuilt || "N/A"}
              </p>
            </div>
          </div>

          {/* Auction Details Section */}
          <div className="print-property-section">
            <h2 className="print-property-section-title">Auction Details</h2>
            <div className="print-property-detail-list">
              <p className="print-property-detail-item">
                Auction Start:{" "}
                {product.auctionStartDate
                  ? new Date(product.auctionStartDate).toLocaleDateString()
                  : "N/A"}{" "}
                {product.auctionStartTime || ""}
              </p>
              <p className="print-property-detail-item">
                Auction End:{" "}
                {product.auctionEndDate
                  ? new Date(product.auctionEndDate).toLocaleDateString()
                  : "N/A"}{" "}
                {product.auctionEndTime || ""}
              </p>
              <p className="print-property-detail-item">
                Start Bid: $
                {product.startBid
                  ? Number(product.startBid).toLocaleString()
                  : "N/A"}
              </p>
              <p className="print-property-detail-item">
                Reserve Price: $
                {product.reservePrice
                  ? Number(product.reservePrice).toLocaleString()
                  : "N/A"}
              </p>
              <p className="print-property-detail-item">
                Minimum Increment: $
                {product.minIncrement
                  ? Number(product.minIncrement).toLocaleString()
                  : "N/A"}
              </p>
              <p className="print-property-detail-item">Event ID: {product.eventID || "N/A"}</p>
            </div>
          </div>
        </div>

        {/* Description Section */}
        <div className="print-property-section">
          <h2 className="print-property-section-title">Property Description</h2>
          <p className="print-property-description">
            {product.propertyDescription || "No description available."}
          </p>
        </div>

        {/* Footer Section */}
        <div className="print-property-footer">
          <p>Generated on: {new Date().toLocaleDateString()}</p>
        </div>
      </div>
    </div>
  );
};

export default PrintProperty;