import React, { useState } from 'react';
import './UserProfile.css'; // External CSS for styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import Spinner from '../../Spinner/Spinner';
import { useMediaQuery } from '@mui/material';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { setUser } from '../../../features/user/userSlice';
import { toast } from 'react-toastify';
import ChangeEmailModal from '../EmailChangeModel/ChangeEmailModal';
const usStates = [
  "Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado",
  "Connecticut", "Delaware", "Florida", "Georgia", "Hawaii", "Idaho",
  "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", "Maine",
  "Maryland", "Massachusetts", "Michigan", "Minnesota", "Mississippi",
  "Missouri", "Montana", "Nebraska", "Nevada", "New Hampshire",
  "New Jersey", "New Mexico", "New York", "North Carolina", "North Dakota",
  "Ohio", "Oklahoma", "Oregon", "Pennsylvania", "Rhode Island",
  "South Carolina", "South Dakota", "Tennessee", "Texas", "Utah",
  "Vermont", "Virginia", "Washington", "West Virginia", "Wisconsin",
  "Wyoming"
];
const UserProfile = () => {
  const user = useSelector((state) => state.user.user);
  console.log(user);
  const isSmallScreen = useMediaQuery('(max-width:768px)');
  const [formData, setFormData] = useState(null);
  const [selectState, setSelectState] = useState(user?.state);
  const [reloadKey, setReloadKey] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (user) {
      setFormData({
        name: user?.name,
        last_name: user?.last_name,
        email: user?.email,
        state: user.state ? user?.state : selectState,
        city: user?.city,
        zipCode: '90001',
        businessPhone: user?.businessPhone,
        userType: user.userType
      })
    }
  }, [user,selectState])
  const [isOpen, setIsOpen] = useState(false);
  const handleClickEmailChange = () => {
      handleOpenModal();
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const toggleDropdown = (event) => {
    event.stopPropagation();
    setIsOpen(!isOpen);
  };
  const handleStateChange = (option) => {
    setSelectState(option);
    setIsOpen(false)
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log(formData)
      const response = await axios.put(`https://viharabackend.onrender.com/api/v1/user/update/${user._id}`, formData);
      dispatch(setUser(response.data.user))
      console.log('User updated successfully:', response.data);
      toast.success('Profile Updated Successfully');
      window.scrollTo({
        top: 0,
        behavior: "smooth", // Enables smooth scrolling
      });
    } catch (error) {
      console.error('Error updating user:');
    }
  };
  if (!user || !formData) {
    return <Spinner />
  }
  return (
    <div className="portal-profile-container">
      <div className='profile-header-container'><h4 className="portal-profile-header">Profile Information </h4><a href="#">Download Your Information</a></div>
      <div className='portal-horizontal-line'></div>
      <form onSubmit={handleSubmit} className="portal-profile-form">
        <div className='user-profile-input-row'>
          <div className="portal-profile-row">
            <div className='portal-profile-label-container' style={{ width: isSmallScreen ? "23%" : "18%" }}>
              <label>I am a </label></div>
            <div className='buyer-agent-container'>
              <div>
                <input
                  type="radio"
                  name="userType"
                  value="Buyer"
                  checked={formData?.userType === 'Buyer'}
                  onChange={handleChange}
                  style={{ marginLeft: "0px" }}
                />
                <label>Buyer</label>
              </div>
              <div>
                <input
                  type="radio"
                  name="userType"
                  value="Agent"
                  checked={formData.userType === 'Agent'}
                  onChange={handleChange}
                />
                <label>Agent</label>
              </div>
            </div>
          </div>
        </div>
        <div className='user-profile-input-row'>
          <div className='portal-profile-row'>
            <div className='portal-profile-label-container'>  <label>First Name <span>*</span></label></div>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className='portal-profile-row'>
            <div className='portal-profile-label-container'> <label>Last Name <span>*</span></label></div>
            <input
              type="text"
              name="last_name"
              value={formData.last_name}
              onChange={handleChange}
              required
            />
          </div>
        </div>


        <div className='user-profile-input-row'>
          <div className="portal-profile-row">
            <div className='portal-profile-label-container'>  <label>Email <span>*</span></label></div>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              disabled
            />
          </div>
          <div className='portal-profile-row'>
            <div className='portal-profile-label-container' style={{ visibility: "hidden" }}>  <label>Email <span>*</span></label></div>
            <a onClick={handleClickEmailChange} className="portal-profile-change-email">
              Change Email
            </a>
          </div>
        </div>
        <div className='user-profile-input-row'>
          <div className="portal-profile-row">
            <div className='portal-profile-label-container'><label>State <span>*</span></label></div>
            <div className="profile-state-select">
              <button
                className={`profile-state-select-input`}
                onClick={toggleDropdown}
                type='button'
                style={{paddingTop:user?.state?"9px":"15px",paddingBottom:user?.state?"9px":"15px"}}
              >
                {selectState ? selectState : formData.state}
                <FontAwesomeIcon icon={!isOpen ? faCaretDown : faCaretUp} className="dropdown-icon" />
              </button>
              {isOpen && (
                <div className="profile-state-select-menu">
                  {usStates.map((option) => (
                    <div
                      key={option}
                      className="profile-state-select-item"
                      onClick={() => handleStateChange(option)}
                    >
                      {option}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="portal-profile-row">
            <div className='portal-profile-label-container'><label>City <span>*</span></label></div>
            <input
              type="text"
              name="city"
              value={formData.city}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className='user-profile-input-row'>
          <div className="portal-profile-row">
            <div className='portal-profile-label-container'> <label>Zip Code <span>*</span></label></div>
            <input
              type="text"
              name="zipCode"
              value={""}
              onChange={handleChange}
              disabled
            />
          </div>
          <div className="portal-profile-row">
            <div className='portal-profile-label-container'> <label>businessPhone <span>*</span></label></div>
            <input
              type="text"
              name="businessPhone"
              value={formData.businessPhone}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className="user-profile-input-row">
          <div className="portal-profile-row" style={{ marginTop: "0rem", marginBottom: "0rem" }}>
            <p className="portal-profile-disclaimer">
              By submitting this form you are agreeing to Vihara&apos;s{" "}
              <a href="/website-terms-of-use">terms of service</a> and{" "}
              <a href="/privacy-statement">privacy policy</a>. Contact{" "}
              <a href="mailto:trisha@vihara.ai" className="trisha-email-link">trisha@vihara.ai</a> with
              any questions.
            </p>
            <p className="portal-profile-disclaimer">
              I agree that Vihara may contact me about their products and
              services.
            </p>
          </div>
        </div>
        <div className="portal-profile-row">
          <div className='portal-profile-label-container'></div>
          <button type="submit" className="portal-profile-submit-btn">
            Submit
          </button>
        </div>
        <p className="portal-profile-get-support">
          Need support?{" "}
          <a href="mailto:trisha@vihara.ai">Go here instead.</a>
        </p>
      </form >
      <ChangeEmailModal open={openModal} handleClose={handleCloseModal} user={user}  />
    </div >
  );
};

export default UserProfile;
