import React, { useState, useContext } from "react";
import "./SignUp.css";
import { Helmet } from "react-helmet";
import { Images } from "../../../constants/constant";
import NavBackground from "../../includes/navBackground";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setUser } from "../../../features/user/userSlice";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import Google from "../../../assets/images/vihara/google.png";
import Apple from "../../../assets/images/vihara/appleImage.png";
import signUpImage from "../../../assets/images/discoverComponent/discoverComponent.png";
import axios from "axios";
const SignUp = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [businessPhone, setBusinessPhone] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  // const [company, setCompany] = useState('');
  const navigate = useNavigate();
  const [firstNameError, setfirstNameError] = useState("");
  const [lastNameError, setlastNameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [businessPhoneError, setBusinessPhoneError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [emailAndPhone, setEmailAndPhone] = useState(null);
  const dispatch = useDispatch();
  const googleAuth = () => {
    window.open(
      "https://viharabackend.onrender.com/auth/google/callback",
      "_self"
    );
  };
  useEffect(() => {
    async function getAllEmail() {
      console.log("in property sample");
      try {
        const response = await axios.get(
          "https://viharabackend.onrender.com/api/v1/user/getEmails"
        );
        setEmailAndPhone(response.data.contacts);
      } catch (error) {
        console.log(error);
      }
    }
    getAllEmail();
  }, []);
  const handlePrivacyClick = () => {
    window.location.href = "/Privacy";
  };
  const validateEmail = (email) => {
    // Email validation regex
    const emailCriteria = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailCriteria.test(email);
  };
  const validatePassword = (password) => {
    const passwordCriteria =
      /^(?=.*[A-Z])(?=.*\d)(?=.*[!#@%$;^])[A-Za-z\d!#@%$;^]{8,}$/;
    return passwordCriteria.test(password);
  };
  const validatePhoneNumber = (number) => {
    // Example regex for 10-digit phone numbers
    const regex = /^\d{10}$/;
    return regex.test(number);
  };
  const phoneChange = (e) => {
    const newPhone = e.target.value;
    setBusinessPhone(newPhone);

    if (!validatePhoneNumber(newPhone)) {
      setBusinessPhoneError("Please enter a valid phone number.");
    } else {
      setBusinessPhoneError("");
      // const existingRecord = emailAndPhone?.find(obj => obj.businessPhone === newPhone);
      // if (existingRecord) {
      //     setBusinessPhoneError('Phone number is already verified for another user. Please use a different phone number.');
      // }
    }
  };
  const isFormValid =
    firstName.trim() !== "" &&
    lastName.trim() !== "" &&
    businessPhone.trim() !== "" &&
    email.trim() !== "" &&
    password.trim() !== "" &&
    confirmPassword.trim() !== "";

  const handleEmailChange = (e) => {
    // if (e.target.value === '') {
    //     setEmail('');
    //     setEmailError('');
    //     return;
    // }
    const newEmail = e.target.value;
    setEmail(newEmail);

    if (!validateEmail(newEmail)) {
      setEmailError("Please enter a valid email address.");
    } else {
      setEmailError("");
      const existingRecord = emailAndPhone?.find(
        (obj) => obj.email === newEmail
      );
      console.log(existingRecord);
      if (existingRecord) {
        setEmailError("Email is already in use");
      }
    }
  };
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const handlePasswordChange = (e) => {
    // if (e.target.value === '') {
    //     setPasswordError('');
    //     setPassword(e.target.value);
    //     return;
    // }
    const newPassword = e.target.value;
    setPassword(newPassword);

    if (!validatePassword(newPassword)) {
      setPasswordError(
        "Password should be 8 or more in length with at least one number, special character (! # @ % $ ; ^), and uppercase letter."
      );
    } else {
      setPasswordError("");
    }
  };

  const handleConfirmPasswordChange = (e) => {
    const newConfirmPassword = e.target.value;
    setConfirmPassword(newConfirmPassword);

    if (newConfirmPassword && newConfirmPassword !== password) {
      setConfirmPasswordError("Passwords do not match.");
    } else {
      setConfirmPasswordError("");
    }
  };
  const handleBlur = (field, value) => {
    if (!value) {
      if (field === "firstnameerror") {
        setfirstNameError("This field is required");
      } else if (field === "lastnameerror") {
        setlastNameError("This field is required");
      } else if (field === "passworderror") {
        setPasswordError("This field is required");
      } else if (field === "confirmpassworderror") {
        setConfirmPassword("This field is required");
      } else if (field === "emailerror") {
        setEmailError("This field is required");
      } else if (field === "phoneerror") {
        setBusinessPhoneError("This field is required");
      }
    }
  };
  const handleSignUp = async (e) => {
    e.preventDefault();
    console.log("in registration");
    if (
      !firstName ||
      !lastName ||
      !email ||
      !password ||
      !confirmPassword
    ) {
      if (!firstName) setfirstNameError("This field is required");
      if (!lastName) setlastNameError("This field is required");
      if (!email) setEmailError("This field is required");
      if (!password) setPasswordError("This field is required");
      // if (!businessPhone) setBusinessPhoneError("This field is required");
      return;
    }
    if (password !== confirmPassword) {
      if (!confirmPassword) setConfirmPasswordError("This field is required");
      return;
    }
    try {
      const response = await axios.post(
        "https://viharabackend.onrender.com/api/v1/user/registerUser",
        {
          name: firstName,
          last_name: lastName,
          email,
          password,
          city,
          state,
        }
      );
      console.log(response);
      // navigate("/verify-otp", {
      //   state: {
      //     user: response.data.user,
      //     token: response.data.token,
      //   },
      // });
      dispatch(setUser(response.data.user));
      localStorage.setItem("token", response.data.token);
      navigate("/");
    } catch (error) {
      console.error("Signup failed", error);
      if (error?.response?.data?.error === "Duplicate email error") {
        toast.error(
          "You already have an account created. Please log into your account."
        );
      }
    }
  };
  return (
    <div className="signup-container">
      <Helmet>
        <title>Sign Up for Vihara AI - Start Your AI Real Estate Journey</title>
        <meta
          name="description"
          content="Join Vihara AI today to transform your real estate auction experience with AI. Easy sign-up, start bidding on properties."
        />
        <meta
          name="keywords"
          content="Sign up Vihara AI, register, AI real estate platform, auction sign-up"
        />
      </Helmet>
      <NavBackground />
      <div className="signup-signup-container">
        <div className="signup-form-container">
          {/* <div className="signup-logo">
                        <img src={Images.logoOnDark} alt="Vihara" />
                    </div> */}
          <div className="welcome-to-vihara-auth">Welcome to Vihara!</div>
          <div className="regsitration-details-sign-up">
            Register for free on Vihara so you can browse through properties,
            submit bids, and receive updates. Already have an account?{" "}
            <span
              onClick={() => (window.location.href = "/login")}
              style={{
                cursor: "pointer",
                color: "#118bF4",
                textDecoration: "underline",
              }}
            >
              Log in
            </span>
          </div>
          <form className="signup-form" onSubmit={handleSignUp}>
            <div className="sign-up-input-group">
              <div>
                <label className="sign-up-input-label">
                  <span>First Name:</span> <span>*</span>{" "}
                </label>
                <input
                  className={`signup-input ${
                    firstNameError ? "input-error" : ""
                  }`}
                  type="text"
                  value={firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                    setfirstNameError("");
                  }}
                  onBlur={() => handleBlur("firstnameerror", firstName)}
                />
                {firstNameError && (
                  <div
                    style={{
                      fontSize: "13px",
                      color: "red",
                      marginBottom: "10px",
                      fontWeight: "300",
                    }}
                  >
                    {firstNameError}
                  </div>
                )}
              </div>
              <div>
                <label className="sign-up-input-label">
                  <span>Last Name:</span> <span>*</span>{" "}
                </label>
                <input
                  className={`signup-input ${
                    lastNameError ? "input-error" : ""
                  }`}
                  type="text"
                  value={lastName}
                  onChange={(e) => {
                    setLastName(e.target.value);
                    setlastNameError("");
                  }}
                  onBlur={() => handleBlur("lastnameerror", lastName)}
                />
                {lastNameError && (
                  <div
                    style={{
                      fontSize: "13px",
                      color: "red",
                      marginBottom: "10px",
                      fontWeight: "300",
                    }}
                  >
                    {lastNameError}
                  </div>
                )}
              </div>
            </div>
            {/* <label className='sign-up-input-label'><span>Company Name:</span> <span>*</span> </label>
                        <input
                            className="signup-input"
                            type="text"
                            value={company}
                            onChange={(e) => setCompany(e.target.value)}
                        /> */}
            <div
              className="sign-up-input-group"
              style={{ marginBottom: "0px" }}
            >
              <div>
                <label className="sign-up-input-label">
                  <span>Password:</span> <span>*</span>
                </label>
                <div
                  className={`password-container ${
                    passwordError ? "input-error" : ""
                  }`}
                >
                  <input
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={handlePasswordChange}
                    onBlur={() => handleBlur("passworderror", password)}
                  />
                  <FontAwesomeIcon
                    icon={showPassword ? faEye : faEyeSlash}
                    className="password-icon"
                    onClick={toggleShowPassword}
                  />
                </div>
              </div>
              <div>
                <label className="sign-up-input-label">
                  <span>Confirm Password:</span> <span>*</span>
                </label>
                <div
                  className={`password-container ${
                    confirmPasswordError ? "input-error" : ""
                  }`}
                >
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    disabled={!validatePassword(password)}
                    onBlur={() =>
                      handleBlur("confirmpassworderror", confirmPassword)
                    }
                  />
                  <FontAwesomeIcon
                    icon={showConfirmPassword ? faEye : faEyeSlash}
                    className="password-icon"
                    onClick={toggleShowConfirmPassword}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                fontSize: "13px",
                color: "red",
                marginBottom: "10px",
                fontWeight: "300",
              }}
            >
              {passwordError}
            </div>
            <div
              style={{
                fontSize: "13px",
                color: "red",
                marginBottom: "2px",
                fontWeight: "300",
              }}
            >
              {confirmPasswordError}
            </div>
            <div className="sign-up-input-group">
              <div>
                <label className="sign-up-input-label">
                  <span>Email:</span> <span>*</span>{" "}
                </label>
                <div>
                  <input
                    className={`signup-input ${
                      emailError ? "input-error" : ""
                    }`}
                    type="email"
                    value={email}
                    onChange={handleEmailChange}
                    onBlur={() => handleBlur("emailerror", email)}
                  />
                  {emailError && (
                    <div
                      style={{
                        fontSize: "13px",
                        color: "red",
                        marginBottom: "10px",
                        fontWeight: "300",
                      }}
                    >
                      {emailError}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <label className="sign-up-input-label">
                  <span>Business Phone </span>
                  {/* <span>*</span>{" "} */}
                </label>
                <input
                  className={`signup-input ${
                    businessPhoneError ? "input-error" : ""
                  }`}
                  type="tel"
                  value={businessPhone}
                  onChange={phoneChange}
                  // onBlur={() => handleBlur("phoneerror", businessPhone)}
                />
                {businessPhoneError && (
                  <div
                    style={{
                      fontSize: "13px",
                      color: "red",
                      marginBottom: "10px",
                      fontWeight: "300",
                    }}
                  >
                    {businessPhoneError}
                  </div>
                )}
              </div>
            </div>
            {/* <div className='sign-up-input-group'>
                            <div>
                                <label className='sign-up-input-label'><span>City:</span> <span>*</span> </label>
                                <input
                                    className="signup-input"
                                    type="text"
                                    value={city}
                                    onChange={(e) => setCity(e.target.value)}
                                /></div>
                            <div>
                                <label className='sign-up-input-label'><span>State:</span> <span>*</span> </label>
                                <input
                                    className="signup-input"
                                    type="text"
                                    value={state}
                                    onChange={(e) => setState(e.target.value)}
                                />
                            </div>
                        </div> */}
            <div className="sign-up-input-group">
              <button className="signup-button" type="submit">
                Register
              </button>
            </div>
          </form>
          <div
            className="signup-links"
            style={{ display: "flex", gap: "5px", flexWrap: "wrap" }}
          >
            <span> By clicking on Register, you accept the</span>
            <span
              style={{
                cursor: "pointer",
                color: "rgb(17, 139, 244)",
                textDecoration: "underline",
              }}
              onClick={() => (window.location.href = "website-terms-of-use")}
            >
              Vihara Terms & Conditions
            </span>{" "}
            and
            <span
              style={{
                cursor: "pointer",
                color: "rgb(17, 139, 244)",
                textDecoration: "underline",
              }}
              onClick={() => (window.location.href = "privacy-statement")}
            >
              Privacy Statement
            </span>
            .
          </div>
          {/* <div className="sign-up-with-google-account-text">
            Or sign in with your Google account
          </div>
          <div className="continue-with-google-sign-up" onClick={googleAuth}>
            <img src={Google} />
            <span>Continue with Google</span>
          </div>
          <div
            style={{ marginTop: "10px" }}
            className="sign-up-with-google-account-text"
          >
            Or sign in with your Apple account{" "}
          </div>
          <div className="continue-with-google-sign-up">
            <img src={Apple} />
            <span>Continue with Apple</span>
          </div>
          <div
            className="signup-links"
            style={{ display: "flex", gap: "5px", flexWrap: "wrap" }}
          >
            <span>
              {" "}
              By clicking on Continue with Google, or Continue with Apple, you
              accept the
            </span>
            <span
              style={{
                cursor: "pointer",
                color: "rgb(17, 139, 244)",
                textDecoration: "underline",
              }}
              onClick={() => (window.location.href = "website-terms-of-use")}
            >
              Vihara Terms & Conditions
            </span>{" "}
            and
            <span
              style={{
                cursor: "pointer",
                color: "rgb(17, 139, 244)",
                textDecoration: "underline",
              }}
              onClick={() => (window.location.href = "privacy-statement")}
            >
              Privacy Statement
            </span>
            .
          </div> */}
        </div>
        <div className="signup-image-container"></div>
      </div>
      {/* <a className="signup-help-link" href="/help">Help</a> */}
    </div>
  );
};

export default SignUp;
