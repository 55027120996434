import React from "react";
import { Link } from "react-router-dom";
import "./Breadcrumbs.css"; // For styling
const Breadcrumb = ({product}) => {
  console.log(product);
  const breadcrumbs = [
    { label: "Back to Search", url: "/auctions" },
    {
      label: product?`${product?.state}`:"Loading..",
      url: `/auctions/?state=${product?.state}`,
    },
    {
      label: product?`${product?.city}`:"Loading..",
      url: `/auctions/?state=${product?.state}&city=${product?.city}`,
    },
    { label: product?`${product?.street}`:"Loading..",}
  ];
  return (
    <div className="breadcrumb-nav">
      <ul className="breadcrumb">
        {breadcrumbs.map((crumb, index) => (
          <li key={index} className="breadcrumb-item">
            {crumb.url ? (
              <Link to={crumb.url} className="breadcrumb-link">
                {crumb.label}
              </Link>
            ) : (
              <span className="breadcrumb-current">{crumb.label}</span>
            )}
            {index < breadcrumbs.length - 1 && (
              <span className="breadcrumb-separator"> &gt; </span>
            )}
          </li>
        ))}
      </ul>
      <div style={{ visibility: "123" }}></div>
    </div>
  );
};

export default Breadcrumb;
