// BuyerInfoStep.jsx
import React from 'react';

const BuyerInfoStep = ({ 
  formData, 
  handleInputChange, 
  buyerType, 
  setBuyerType, 
  selectedForm, 
  setSelectedForm, 
  currentBid,
  buyersPremium 
}) => {
  const totalPurchasePrice = currentBid + buyersPremium;

  return (
    <div className="submit-checkout-form-step-content">
      <div className="submit-checkout-form-section">
        <div className="submit-checkout-form-section-title">
          Please complete the following information:
        </div>

        {/* Highest and Best Section */}
        <div className="submit-checkout-form-highest-best">
          <div className="submit-checkout-form-section-title">HIGHEST AND BEST</div>
          <div className="submit-checkout-form-price-info">
            <div className="submit-checkout-form-price-item">
              <div className="submit-checkout-form-price-label">Amount (USD)</div>
              <div className="submit-checkout-form-price-value">
                ${currentBid.toLocaleString()}
              </div>
            </div>
            <div className="submit-checkout-form-price-item">
              <div className="submit-checkout-form-price-label">Buyer's Premium</div>
              <div className="submit-checkout-form-price-value">
                ${buyersPremium.toLocaleString()}
              </div>
            </div>
            <div className="submit-checkout-form-price-item">
              <div className="submit-checkout-form-price-label">Total Purchase Price</div>
              <div className="submit-checkout-form-price-value">
                ${totalPurchasePrice.toLocaleString()}
              </div>
            </div>
          </div>
        </div>

        {/* Pre-filled Form Selection */}
        {/* Buyer Type Selection */}
        <div className="submit-checkout-form-buyer-type">
          <div className="submit-checkout-form-section-title">BUYER TYPE</div>
          <div className="submit-checkout-form-radio-group">
            <label className="submit-checkout-form-radio-label">
              <input
                type="radio"
                name="buyerType"
                value="individual"
                checked={buyerType === 'individual'}
                onChange={(e) => setBuyerType(e.target.value)}
              />
              Individual
            </label>
            <label className="submit-checkout-form-radio-label">
              <input
                type="radio"
                name="buyerType"
                value="company"
                checked={buyerType === 'company'}
                onChange={(e) => setBuyerType(e.target.value)}
              />
              Company
            </label>
          </div>
        </div>

        {/* Company Information (if applicable) */}
        {buyerType === 'company' && (
          <div className="submit-checkout-form-company-info">
            <div className="submit-checkout-form-field">
              <label>
                Company Name
              </label>
              <input
                type="text"
                value={formData.company}
                onChange={(e) => handleInputChange('company', e.target.value)}
                className="submit-checkout-form-input"
              />
            </div>
            <div className="submit-checkout-form-field">
              <label>
                Entity Type
              </label>
              <select
                value={formData.entityType}
                onChange={(e) => handleInputChange('entityType', e.target.value)}
                className="submit-checkout-form-select"
              >
                <option value="">Select Entity Type</option>
                <option value="llc">LLC</option>
                <option value="corporation">Corporation</option>
                <option value="partnership">Partnership</option>
                <option value="trust">Trust</option>
              </select>
            </div>
            <div className="submit-checkout-form-field">
              <label>
                Tax ID
              </label>
              <input
                type="text"
                value={formData.taxId}
                onChange={(e) => handleInputChange('taxId', e.target.value)}
                className="submit-checkout-form-input"
                placeholder="XX-XXXXXXX"
              />
            </div>
          </div>
        )}

        {/* Personal Information */}
        <div className="submit-checkout-form-personal-info">
          <div className="submit-checkout-form-field-grid">
            <div className="submit-checkout-form-field">
              <label>
                First Name
              </label>
              <input
                type="text"
                value={formData.firstName}
                onChange={(e) => handleInputChange('firstName', e.target.value)}
                className="submit-checkout-form-input"
              />
            </div>
            <div className="submit-checkout-form-field">
              <label>
                Last Name
              </label>
              <input
                type="text"
                value={formData.lastName}
                onChange={(e) => handleInputChange('lastName', e.target.value)}
                className="submit-checkout-form-input"
              />
            </div>
            <div className="submit-checkout-form-field">
              <label>
                Mobile Phone
              </label>
              <input
                type="tel"
                value={formData.mobilePhone}
                onChange={(e) => handleInputChange('mobilePhone', e.target.value)}
                className="submit-checkout-form-input"
                placeholder="(805)-878-1682"
              />
            </div>
            <div className="submit-checkout-form-field">
              <label>
                Email
              </label>
              <input
                type="email"
                value={formData.email}
                onChange={(e) => handleInputChange('email', e.target.value)}
                className="submit-checkout-form-input"
              />
            </div>
          </div>
        </div>

        {/* Address Information */}
        <div className="submit-checkout-form-address-info">
          <div className="submit-checkout-form-field">
            <label>
              Street Address
            </label>
            <input
              type="text"
              value={formData.address}
              onChange={(e) => handleInputChange('address', e.target.value)}
              className="submit-checkout-form-input"
              style={{width:"97%"}}
            />
          </div>
          <div className="submit-checkout-form-field-grid">
            <div className="submit-checkout-form-field">
              <label>
                City
              </label>
              <input
                type="text"
                value={formData.city}
                onChange={(e) => handleInputChange('city', e.target.value)}
                className="submit-checkout-form-input"
              />
            </div>
            <div className="submit-checkout-form-field">
              <label>
                State
              </label>
              <input
                type="text"
                value={formData.state}
                onChange={(e) => handleInputChange('state', e.target.value)}
                className="submit-checkout-form-input"
              />
            </div>
            <div className="submit-checkout-form-field">
              <label>
                ZIP Code
              </label>
              <input
                type="text"
                value={formData.zipCode}
                onChange={(e) => handleInputChange('zipCode', e.target.value)}
                className="submit-checkout-form-input"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyerInfoStep;