// SubmitCheckoutForm.jsx
import React, { useState } from 'react';
import { Dialog, DialogContent} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX, faArrowLeft, faArrowRight, faCheck } from "@fortawesome/free-solid-svg-icons";
import { toast } from 'react-toastify';
import BuyerInfoStep from './BuyerInfoStep';
import LegalDisclosuresStep from './LegalDisclosuresStep';
import ReviewStep from './ReviewStep';
import ContractStep from './ContractStep';
import './SubmitCheckOutForm.css';
const Steps = {
  BUYER_INFO: 1,
  LEGAL_DISCLOSURES: 2,
  REVIEW: 3,
  CONTRACT: 4
};

const SubmitCheckoutForm = ({ 
  isOpen, 
  onClose, 
  currentBid, 
  buyersPremium, 
  product 
}) => {
  const [activeStep, setActiveStep] = useState(Steps.BUYER_INFO);
  const [selectedForm, setSelectedForm] = useState('');
  const [buyerType, setBuyerType] = useState('individual');
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    mobilePhone: '',
    email: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    company: '',
    entityType: '',
    taxId: '',
    agreeToTerms: false,
    acknowledgeInspection: false,
    understandFinancing: false,
    acceptPropertyCondition: false,
    acknowledgeClosingTimeline: false,
    confirmInformation: false,
    agreeToContract: false,
    agreeToDeposit: false,
    acceptTerms: false
  });

  const steps = [
    { number: 1, text: 'BUYER INFO' },
    { number: 2, text: 'LEGAL DISCLOSURES' },
    { number: 3, text: 'REVIEW' },
    { number: 4, text: 'CONTRACT' }
  ];

  const handleInputChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleNext = () => {
    if (canProceed()) {
      setActiveStep(prev => prev + 1);
      window.scrollTo(0, 0);
    } else {
      toast.error('Please complete all required fields');
    }
  };

  const handleBack = () => {
    setActiveStep(prev => prev - 1);
    window.scrollTo(0, 0);
  };

  const handleSubmit = async () => {
    if (!canProceed()) {
      toast.error('Please complete all required fields');
      return;
    }

    try {
      // Here you would submit the form data to your backend
      console.log('Form submitted:', formData);
      toast.success('Purchase agreement submitted successfully!');
      onClose();
    } catch (error) {
      toast.error('Error submitting form. Please try again.');
      console.error('Submission error:', error);
    }
  };

  const canProceed = () => {
    switch (activeStep) {
      case Steps.BUYER_INFO:
        const requiredFields = ['firstName', 'lastName', 'mobilePhone', 'email', 
                              'address', 'city', 'state', 'zipCode'];
        if (buyerType === 'company') {
          requiredFields.push('company', 'entityType', 'taxId');
        }
        return requiredFields.every(field => formData[field]?.trim());

      case Steps.LEGAL_DISCLOSURES:
        return formData.agreeToTerms &&
               formData.acknowledgeInspection &&
               formData.understandFinancing &&
               formData.acceptPropertyCondition &&
               formData.acknowledgeClosingTimeline;

      case Steps.REVIEW:
        return formData.confirmInformation;

      case Steps.CONTRACT:
        return formData.agreeToContract &&
               formData.agreeToDeposit &&
               formData.acceptTerms;

      default:
        return false;
    }
  };

  const renderStepContent = () => {
    switch (activeStep) {
      case Steps.BUYER_INFO:
        return (
          <BuyerInfoStep
            formData={formData}
            handleInputChange={handleInputChange}
            buyerType={buyerType}
            setBuyerType={setBuyerType}
            selectedForm={selectedForm}
            setSelectedForm={setSelectedForm}
            currentBid={currentBid}
            buyersPremium={buyersPremium}
          />
        );

      case Steps.LEGAL_DISCLOSURES:
        return (
          <LegalDisclosuresStep
            formData={formData}
            handleInputChange={handleInputChange}
          />
        );

      case Steps.REVIEW:
        return (
          <ReviewStep
            formData={formData}
            handleInputChange={handleInputChange}
            product={product}
            currentBid={currentBid}
            buyersPremium={buyersPremium}
            buyerType={buyerType}
          />
        );

      case Steps.CONTRACT:
        return (
          <ContractStep
            formData={formData}
            handleInputChange={handleInputChange}
            product={product}
            currentBid={currentBid}
            buyersPremium={buyersPremium}
          />
        );

      default:
        return null;
    }
  };

  return (
    <div className="submit-checkout-form-outer-container">
    <Dialog open={isOpen} onClose={onClose}>
      <div className="submit-checkout-form-container">
        {/* Close button */}
        <button onClick={onClose} className="submit-checkout-form-close">
          <FontAwesomeIcon icon={faX} />
        </button>

        {/* Progress Steps */}
        <div className="submit-checkout-form-progress">
          {steps.map((step) => (
            <div 
              key={step.number}
              className={`submit-checkout-form-step ${activeStep === step.number ? 'active' : ''} 
                         ${activeStep > step.number ? 'completed' : ''}`}
            >
              <div className="submit-checkout-form-step-number">
                {activeStep > step.number ? (
                  <FontAwesomeIcon icon={faCheck} />
                ) : (
                  step.number
                )}
              </div>
              <span className="submit-checkout-form-step-text">{step.text}</span>
            </div>
          ))}
        </div>

        {/* Form Content */}
        <div className="submit-checkout-form-content">
          {renderStepContent()}
        </div>

        {/* Navigation Buttons */}
        <div className="submit-checkout-form-navigation">
          {activeStep > Steps.BUYER_INFO && (
            <button 
              onClick={handleBack}
              className="submit-checkout-form-back-button"
            >
              <FontAwesomeIcon icon={faArrowLeft} /> Back
            </button>
          )}
          
          {activeStep < Steps.CONTRACT ? (
            <button
              onClick={handleNext}
              disabled={!canProceed()}
              className="submit-checkout-form-next-button"
            >
              Next <FontAwesomeIcon icon={faArrowRight} />
            </button>
          ) : (
            <button
              onClick={handleSubmit}
              disabled={!canProceed()}
              className="submit-checkout-form-submit-button"
            >
              Submit Purchase Agreement
            </button>
          )}
        </div>
      </div>
    </Dialog>
    </div>
  );
};

export default SubmitCheckoutForm;