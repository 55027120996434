import React from "react";
import "./LandingPage.css"
const LandingPage = () => {
  return (
    <div className="landing-page-container">
      {/* Navbar */}
      {/* <nav className="landing-page-navbar">
        <div className="landing-page-logo"><img  className="logo-small" src={"https://www.vihara.ai/static/media/vihara-new-logo.1e0ecd4b8707813c361a.jpeg"} /></div>
        <ul className="landing-page-nav-links">
        </ul>
      </nav> */}
      {/* Hero Section */}
      <div className="landing-page-hero">
        <div className="landing-page-hero-content">
          <h1>Uniting What Matters</h1>
          <p>
          Linking you to a comprehensive range of services across the default ecosystem.
          </p>
          <p>
          Attend MBA's Servicing Solutions Conference & Expo in Dallas, February 4–7, 2025. Discover how we connect servicers and investors to seamless default services with cutting-edge technology and industry expertise.
          </p>
        </div>

        <div className="landing-page-form">
          <form>
            <label>First Name *</label>
            <input type="text" placeholder="Jane" required />

            <label>Last Name *</label>
            <input type="text" placeholder="Smith" required />

            <label>Company Name *</label>
            <input type="text" placeholder="Your Company" required />

            <label>Email Address *</label>
            <input type="email" placeholder="jane@example.com" required />

            <label>Select your preferred contact method *</label>
            <div className="landing-page-radio-group">
              <label>
                <input type="radio" name="contact" value="email" required />
                Email
              </label>
              <label>
                <input type="radio" name="contact" value="phone" required />
                Phone
              </label>
            </div>

            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
      <Meeting/>
      <MortgageServicing/>
      <AssetDecisions/>
      <ClientFeedback/>
    </div>
  );
};
export default LandingPage;
const Meeting = () => {
  return (
    <div className="landing-page landing-page--meeting">
      <div className="landing-page__video-container">
        <iframe
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/VIDEO_ID"
          title="Meet the Vihara team at MBA's Servicing Solutions Conference & Expo"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
      <div className="landing-page__content">
        <h1 className="landing-page__title">The MBA Servicing Solutions Conference & Expo</h1>
        <p className="landing-page__description">
        Change is the only constant in mortgage servicing. Schedule a meeting today to learn how we can help you navigate regulatory shifts and address your specific challenges.
        </p>
        <button className="landing-page__schedule-meeting-button">Schedule a meeting</button>
      </div>
    </div>
  );
};
const MortgageServicing = () => {
  return (
    <div className="mortgage-servicing-container">
      <div className="mortgage-servicing-header">
        <h2>Technology designed to provide clear insights for justifying asset decisions.</h2>
        <p>Vihara’s innovative EXOS One Marketplace® consolidates essential information for servicers and investors managing default portfolios into one powerful, customizable tool. Tailored to each client’s goals, it adapts to evolving regulations for informed asset decision-making.
</p>
      </div>

      <div className="mortgage-servicing-wheel">
          <img src="https://img.freepik.com/premium-photo/house-signers-signing-signature-loan-document-home-ownership-with-real-estate-agents-ownership-mortgage-real-estate-property-investment-home-insurance_11304-2037.jpg?w=740"/>
      </div>
    </div>
  );
};
const AssetDecisions = () => {
  return (
    <div className="landing-page landing-page--meeting">
      <div className="landing-page__video-container">
        <iframe
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/VIDEO_ID"
          title="Meet the Vihara team at MBA's Servicing Solutions Conference & Expo"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
      <div className="landing-page__content">
        <h1 className="landing-page__title">Technology designed to help you make asset decisions with clear, data-driven justification.</h1>
        <p className="landing-page__description">
       Vihara’s EXOS One Marketplace® consolidates key data for servicers and investors into a single tool for informed asset decisions. Customizable to client goals, it updates with new regulations.
        </p>
        <button className="landing-page__schedule-meeting-button">Schedule a meeting</button>
      </div>
    </div>
  );
};

const ClientFeedback = () => {
  return (
    <div className="client-feedback-container">
      <h2 className="client-feedback-title">
      Client Testimonials
      </h2>
      <div className="client-feedback-card">
        <p className="client-feedback-quote">
          “We always seek the best, and Vihara consistently exceeds our expectations. They went the extra mile to tailor EXOS One Marketplace to our needs, allowing my team to spend less time on research and more on impactful decision-making.”
        </p>
        <p className="client-feedback-author">
          Nikum Patel, SVP, Transaction Management, Binomial Real Estate
        </p>
      </div>
    </div>
  );
};
