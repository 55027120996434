import React from "react";
import "../CommonConditions.css";
import "./WebSiteTermsOfUse.css";
import NavBackground from "../../../includes/navBackground";

const WebSiteTermsOfUse = () => {
  return (
    <div className="conditions-background">
      <NavBackground />
      <div className="privacy-term-main-container">
        <div className="privacy-content-updated-date">
          Updated on 2nd May 2024
        </div>
        <div className="privacy-content-main-heading">Website Terms of Use</div>
        <div className="privacy-content-main-section">
          <div className="privacy-content-additional-section">
            <h4>Additional Links</h4>

            <h4>
              <a href="/privacy-statement">Privacy Statements</a>
            </h4>

            <h4>
              <a href="/website-terms-of-use">Website Terms of Use</a>
            </h4>
            <h4>
              <a href="#">Cookie Preferences</a>{" "}
            </h4>
            <h4>
              <a href="/privacy-choice">Privacy Choices</a>{" "}
            </h4>
            <h4>
              <a href="/participation-terms">Participation Terms </a>
            </h4>
            <h4>
              <a href="/accessibility">Accessibility</a>
            </h4>
          </div>
          <div className="privacy-content-main-content-section">
            <p>
              These Terms of Use (&quot;Terms&quot;) govern the use of the
              websites owned and operated by Vihara.ai, along with its
              affiliates and subsidiaries (referred to collectively as
              &quot;we&quot;, &quot;us&quot;, &quot;our&quot;,
              &quot;Vihara.ai&quot;, or the &quot;Company&quot;), including
              www.vihara.ai and any other website where these Terms are
              published (&quot;Websites&quot;). These Terms also extend to all
              tools, documents, applications (including mobile applications),
              and other services provided by Vihara.ai. Together, we refer to
              these Websites, tools, and applications as &quot;Services.&quot;
              Please review this document carefully as it constitutes a legally
              binding agreement between you, your heirs, representatives
              (collectively referred to as &quot;you&quot;), and Vihara.ai.
            </p>
            <p className="termsOfUses-text-box">
              Below certain sections, we&apos;ve added annotations that are not
              legally binding but serve to emphasize important points and aid in
              understanding the text.
            </p>

            <div className="privacy-content-text-heading">
              Accepting these Terms of Use and Changes to these Terms of Use
            </div>

            <p>
              By accessing or utilizing the Services, you are consenting to
              abide by these Terms of Use and engaging in a legally binding
              agreement with us.{" "}
              <b>
                If you do not agree with the Terms of Use, which include the
                mandatory arbitration clause and class action waiver outlined in
                Section 13 below, you are not permitted to utilize the Services
                or establish an account.
              </b>
            </p>

            <p>
              As our business evolves and advances, we reserve the right to
              modify these Terms of Use periodically and will publish an updated
              version on this page. We encourage you to check regularly for any
              revisions. In the event of any significant alterations to these
              Terms of Use, we will notify you via email or on the Services as
              appropriate. Otherwise, your continued use of the Services
              following such changes will signify your acceptance of the updated
              terms.
            </p>
            <p className="termsOfUses-text-box">
              By utilizing the Services, you are consenting to be legally bound
              by this agreement, which includes the binding arbitration clause
              provided below. We reserve the right to modify this agreement
              periodically, so please ensure to check this page regularly.
            </p>

            <div className="privacy-content-text-heading">
              Eligibility and Registration
            </div>

            <p>
              To use the Services, you must be at least 18 years old or, if the
              age of majority in your jurisdiction exceeds 18 years old, you
              must meet the age of majority requirement. You have the option to
              create an account and supply specific details, such as a valid
              email address and password. If you wish to participate in
              auctions, make offers, or engage in marketing events, registration
              with us is necessary. You agree to furnish accurate and truthful
              information and to maintain its accuracy and currency.
              Safeguarding the confidentiality and security of your information
              is your responsibility, and you agree to notify us promptly of any
              unauthorized use. Your password should not be shared with third
              parties unaffiliated with us. You are solely accountable for all
              activities conducted under your password, account, username, or
              registration, whether by you or others. We are entitled to act
              upon instructions received via your account or registration and
              bear no liability for any losses resulting from your
              non-compliance with this provision. By providing your information,
              you consent to our contacting you regarding your interest in us or
              the Services through email, phone, or any other provided contact
              information. Refer to Section 15.B. for further details. You can
              opt out of marketing communications by following the instructions
              in our Privacy Statement.
            </p>
            <p className="termsOfUses-text-box">
              The Services are designed for users aged 18 and above, therefore,
              you MUST be 18 years old to access them. When creating an account
              or registering, it&lsquo;s crucial to maintain accurate and
              current information to ensure we can reach you with updates about
              the Services. You are accountable for your account and
              registration details. Should you forget your password or suspect
              unauthorized access to your account, please contact us promptly
              for assistance.
            </p>
            <div className="privacy-content-text-heading">
              Intellectual Property
            </div>

            <p>
              All components of the Services, encompassing the selection,
              compilation, arrangement, and presentation of all materials on the
              Websites, tools, and applications, are subject to copyright owned
              by us or our licensors and content suppliers. They are safeguarded
              by U.S. and international laws. By using the Services, you consent
              to comply with all applicable copyright laws, including any
              notices or restrictions posted on the Services. You acknowledge
              that the use of any content from the Services without our explicit
              prior written consent is strictly prohibited. You are not entitled
              to any proprietary rights, including intellectual property rights,
              in or to the Services. Recognize that the Services are valuable
              commercial products, developed with significant time and financial
              investment.
            </p>

            <p>
              Usage of our trademarks, trade names, service marks, copyrights,
              or logos in a manner that suggests ownership or association with
              you is prohibited unless you have obtained our written consent.
              You acknowledge that you possess no ownership rights in or to any
              of these items.
            </p>

            <p>
              Framing of the Websites is not permitted. You may link to the
              Websites, under the condition that you acknowledge and agree not
              to link the Websites to any site containing inappropriate,
              profane, defamatory, infringing, obscene, indecent, or unlawful
              content, or information that violates any intellectual property,
              proprietary, privacy, or publicity rights.
            </p>

            <p className="termsOfUses-text-box">
              The content provided on the Services is protected by copyright.
              While you are permitted to use the content, you are not allowed to
              modify it or remove any copyright notices without obtaining our
              permission. Employing your web design skills to frame our Websites
              and misrepresent them as your own is prohibited. Additionally,
              linking to our Websites from sites containing objectionable
              content is not permitted.
            </p>

            <div className="privacy-content-text-heading">
              Your License to Use the Services
            </div>

            <p>
              The Services are solely owned by us. Nonetheless, we provide you
              with a limited, non-exclusive, non-transferable license to access
              and utilize the Services solely as outlined in the Terms of Use.
              You agree not to use, replicate, adapt, alter, create derivative
              works from, distribute, license, sell, transfer, publicly display,
              publicly perform, transmit, broadcast, or otherwise exploit the
              Services, except as explicitly permitted in these Terms of Use.
              Any infringement of these license terms by you may result in
              immediate termination of your right to use the Services. We retain
              all rights, titles, and interests not expressly granted under this
              license to the fullest extent permitted by applicable laws.
            </p>

            <p className="termsOfUses-text-box">
              While we possess ownership of the Services, we permit your usage
              strictly in compliance with the Terms of Use. Failure to adhere to
              these regulations may lead to the termination of your access to
              the Services.
            </p>

            <div className="privacy-content-text-heading">Service Rules</div>

            <p>
              To use the Services, you must comply with several rules. You agree
              not to:
            </p>

            <ul>
              <li>Breach these Terms of Use;</li>
              <li>
                Engage in automated scraping, monitoring, or copying of any
                portion of the Services using robots, scrapers, or similar
                methods, except for manual access to publicly available
                sections;
              </li>
              <li>
                Violate the restrictions set in robot exclusion headers of the
                Services or bypass measures preventing or restricting access;
              </li>
              <li>Create derivative works from the Services;</li>
              <li>
                Engage in activities that compete with our business or affect
                our revenue;
              </li>
              <li>
                Compromise our computer systems or transmit harmful software
                like viruses or worms;
              </li>
              <li>Disrupt the use and enjoyment of the Services by others;</li>
              <li>Attempt unauthorized access to the Services;</li>
              <li>
                Utilize any part of the Services for unsolicited mailings or
                spam;
              </li>
              <li>
                Infringe upon the rights of third parties, including copyright,
                trademark, privacy, or any other intellectual property rights;
              </li>
              <li>
                Engage in threatening, stalking, harming, or harassing behavior
                towards others, misleading or deceiving others, promoting
                bigotry or discrimination, defamation, or any other
                objectionable conduct;
              </li>
              <li>
                Solicit personal information, promote illegal substances, or
                submit/transmit pornography;
              </li>
              <li>Violate any laws.</li>
            </ul>
            <p className="termsOfUses-text-box">
              To access the Services, you must adhere to our guidelines. These
              rules are established to maintain the safety and enjoyment of the
              Services for all users.
            </p>

            <div className="privacy-content-text-heading">
              Your Content and Submissions
            </div>

            <p>
              You bear full responsibility for all content you post, transmit,
              upload, or otherwise contribute to the Services (&quot;Submissions&quot;).
              These Submissions may be associated with your actual name or
              username. Once published, you cannot retract these Submissions.
              Unless stated otherwise, you grant us, our subsidiaries, and
              affiliates a nonexclusive, transferable, royalty-free, perpetual,
              irrevocable, and fully sublicensable right to utilize, modify,
              adapt, publish, translate, create derivative works from,
              distribute, copy, and display any Submissions globally in any
              form.
            </p>
            <p>
              You affirm that you own or control all rights to your Submissions,
              ensuring they do not breach the Terms of Use or harm any
              individual or entity. We do not assume responsibility or liability
              for any content, opinion, recommendation, or advice provided in
              your Submissions or by any third party. We reserve the right to
              post our own versions of content (including property photos or
              descriptions) at our discretion.
            </p>
            <p>
              You authorize us to enforce copyright and other intellectual
              property claims against third parties who have scraped, copied, or
              distributed your Submissions without authorization and in
              violation of the Terms of Use.
            </p>
            <p>
              Review our Privacy Statement before making any Submissions. If you
              disagree with our Privacy Statement, refrain from making any
              Submissions.
            </p>
            <p>
              In addition to adhering to the rules in these Terms of Use, you
              agree to the following guidelines when making Submissions. This
              list is not exhaustive, and we reserve the right to determine what
              constitutes inappropriate use of the Services. Inappropriate
              conduct may lead to appropriate measures at our discretion. By way
              of example:
            </p>

            <ul>
              <li>
                Maintain a courteous demeanor towards other users, even when
                encountering disagreeable content
              </li>
              <li>
                Keep Submissions relevant and avoid promoting other products,
                services, or fundraising endeavors;
              </li>
              <li>
                Refrain from infringing upon our rights or those of third
                parties;
              </li>
              <li>
                Do not impersonate others or create Submissions from fake or
                anonymous profiles;
              </li>
              <li>
                Acknowledge our non-liability for Submissions made by you or
                others;
              </li>
              <li>
                Understand our right to remove or edit content and Submissions
                at our discretion;
              </li>
              <li>
                Avoid inappropriate content, including personal attacks,
                offensive language, or fraudulent material;
              </li>
              <li>
                Obtain consent before sharing images of individuals, especially
                minors;
              </li>
              <li>
                Do not distribute viruses or files capable of damaging
                computers;
              </li>
              <li>
                Acknowledge our authority to delete, modify, or remove
                Submissions at any time, with the responsibility to backup such
                content resting solely on you;
              </li>
              <li>
                Understand the inherent risk in using the Services, recognizing
                that Submissions may not always be accurate. While we may
                monitor Submissions, we are not obligated to do so.
              </li>
            </ul>

            <p className="termsOfUses-text-box">
              You bear responsibility for all submissions you make to the
              Services and acknowledge that we are not obliged to publish or
              maintain any content you provide. Once information is posted
              online, it may be challenging to remove, particularly if multiple
              copies exist. By uploading content to the Services, you grant us
              permission to distribute it in various forms (such as promotional
              materials). If a third party uses your content in a manner that
              breaches these Terms of Use, you agree that we may pursue legal
              action against that party. All submissions must adhere to our
              guidelines.
            </p>

            <div className="privacy-content-text-heading">
              Reporting Copyright Infringement
            </div>
            <p>
              We&apos;ve established and reasonably enforced a policy for terminating
              subscribers and account holders of our Services who repeatedly
              infringe copyrights. If you believe that your copyright, or the
              copyright of someone you represent, has been infringed, you can
              send a written DMCA Notice of Alleged Infringement (&quot;Notice&quot;) to
              our Copyright Agent. Your Notice must include the following:
            </p>

            <ol className="alphabet-list">
              <li>
                Identification of the copyrighted work you claim has been
                infringed, or a representative list if multiple works are
                involved.
              </li>
              <li>
                Identification of the infringing material to be removed or
                disabled, along with sufficient information for us to locate it,
                including the URL if it&apos;s on our website.
              </li>
              <li>
                Your mailing address, telephone number, and, if available, email
                address.
              </li>
              <li>
                Affirmation of the following statements within the Notice:
                <ul>
                  <li>
                  &quot;I believe in good faith that the disputed use of the
                    copyrighted material is not authorized by the copyright
                    owner, its agent, or the law (e.g., as a fair use).&quot;
                  </li>
                  <li>
                  &quot;The information provided in this Notice is accurate and,
                    under penalty of perjury, I declare that I am the owner of
                    the copyright or have the authority to act on behalf of the
                    copyright owner.&quot;
                  </li>
                </ul>
              </li>
              <li>
                Your full legal name and either an electronic or physical
                signature.
              </li>
            </ol>

            <p>
              Deliver this Notice, with all items completed, to Company’s
              Copyright Agent at trisha@vihara.ai.
            </p>
            <div>
              <span>Vihara.ai Copyright Agent</span>
              <br />
              <span>1335 S Milpitas Blvd, Milpitas, CA 95035</span>
              <br />
              <span>
                <a href="mailto:trisha@vihara.ai">trisha@vihara.ai</a>
              </span>
            </div>

            <p>
              You can leave a message for our Copyright Agent at (916) 813-4649,
              but you cannot use this number to submit a Notice or to notify us
              of alleged copyright infringement. To be valid, a Notice must
              follow the instructions written above and must be in writing.{" "}
            </p>

            <p className="termsOfUses-text-box">
              Follow the precise procedure outlined here to notify us of any
              alleged copyright infringement on the Services.
            </p>

            <div className="privacy-content-text-heading">
              Disclaimer of Warranties
            </div>

            <p>
              You agree that your use of the Services is at your own risk and
              acknowledge that all information contained in the Services is
              provided “as is” and “as available,” and that we disclaim all
              warranties, either express or implied, including but not limited
              to implied warranties of merchantability, fitness for a particular
              purpose, title, and non-infringement. We make no representations
              or guarantees regarding the compatibility of the Services with
              your equipment or the absence of errors, interruptions, viruses,
              or other harmful elements, and we disclaim any liability related
              to such matters. You agree that we may change the content or
              technical specifications of the Services at any time at our
              discretion, which may affect your ability to access the Services.
            </p>

            <p>
              We make no guarantees, representations, or warranties regarding
              the accuracy, reliability, completeness, or uninterrupted
              availability of the Services or the information available through
              them. Any documents, pictures, or other information provided on
              the Services are for informational purposes only and may not
              reflect the current condition of a property. Posting pictures on
              the Services does not guarantee the presence of represented items
              when a buyer takes possession of a property. It is recommended
              that you conduct your own due diligence and seek independent
              advice, including legal advice, to ensure the accuracy of all
              information obtained.
            </p>
            <p>
              You understand and acknowledge that the information provided
              through the Services may change and should be checked frequently
              for updates. Some content, services, and information available
              through the Services may belong to or be submitted by third
              parties, and you agree that we are not responsible for such
              content, services, or information. Third-party content may have
              additional terms of use that you agree to by accessing it. We do
              not endorse or control the content of other websites, services, or
              goods linked to or from the Services.
            </p>

            <p>
              Any use of third-party content, services, and information is at
              your own risk and should be used for internal valuation or
              management purposes within the scope of your licensed or regular
              business activity. We and third-party providers of such content,
              services, and information are not liable for any losses, costs, or
              damages arising from the misuse of, errors in, or miscalculations
              of value contained in such content, services, and information.
              Valuation reports, pricing, or property information provided
              through the Services do not constitute an appraisal or broker
              price opinion and are provided on an “as-is, as available” basis.
              You agree not to use the Services for commercial exploitation and
              to evaluate the merits and risks of purchasing a property using
              the Services, consulting legal and tax professionals as needed.
            </p>

            <p className="termsOfUses-text-box">
              You assume all risks associated with using the Services, as we do
              not oversee or take responsibility for every aspect of them. For
              instance, we don&apos;t verify the accuracy of the content, so you
              should exercise caution when using it. It&apos;s especially important
              for you to conduct your own research on properties listed on the
              Services and refrain from relying solely on the information
              provided when making decisions about them.
            </p>

            <div className="privacy-content-text-heading">
              Limitations of Liability
            </div>

            <p>
              Under no circumstances, including negligence, shall we, our
              subsidiaries, or affiliates be held liable to you or any third
              party for any direct, indirect, incidental, consequential,
              special, punitive, or exemplary damages arising from your use of
              or inability to use the Services. This includes damages caused by
              any failure of performance, error, omission, interruption, defect,
              delay in operation of transmission, computer virus, or line
              failure associated with your use of the Services, even if we have
              been advised of the possibility of such damages. Depending on the
              applicable law, limitations or exclusions of liability for
              incidental or consequential damages may not be permitted.
              Therefore, the aforementioned exclusions and limitations may or
              may not apply to you. To the extent allowed by law, our total
              liability associated with the Services for damages will not exceed
              the amount you paid to access the Services or one hundred dollars
              ($100), whichever is greater.
            </p>

            <p className="termsOfUses-text-box">
              Unless provided otherwise by state law, we are not responsible for
              any problems arising from your use of the Services. Our liability
              will not exceed the amount you paid, if any, to access the
              Services, or $100, whichever is greater.
            </p>

            <div className="privacy-content-text-heading">Indemnity</div>

            <p>
              You agree to protect, defend, and absolve us, our subsidiaries,
              and affiliates from any claims or demands made by third parties
              arising from: (i) your use of the Services; (ii) your infringement
              of any third-party rights, including copyrights, property, or
              privacy rights; (iii) the content you submit; or (iv) your
              violation of the Terms of Use. We reserve the right, at your
              expense, to take over the exclusive defense and control of any
              matter subject to indemnification by you, and you agree to
              cooperate with us in such defense. You also agree not to settle
              any matter in which you have indemnity obligations without our
              prior written consent. We will make reasonable efforts to inform
              you of any such claims, actions, or proceedings once we become
              aware of them.
            </p>
            <p className="termsOfUses-text-box">
              You consent to protect and indemnify us from any claims or damages
              resulting from your use of the Services or violation of the Terms
              of Use.
            </p>

            <div className="privacy-content-text-heading">
              Privacy Statement
            </div>
            <p>
              Our handling of your information is consistently regulated by our
              Privacy Statement. This document outlines how we collect and
              utilize your information concerning the Services and is an
              integral part of these Terms of Use. By utilizing the Services,
              you agree to the collection and utilization of your information in
              accordance with the Privacy Statement.
            </p>
            <p className="termsOfUses-text-box">
              Apart from this agreement, your utilization of the Services is
              contingent upon our Privacy Statement. This document holds
              significance as it dictates the protocols for the utilization of
              your personal and other information by us.
            </p>

            <div className="privacy-content-text-heading">
              Termination or Stopping Use of the Services
            </div>
            <p>
              You have the freedom to cease using the Services at your
              discretion and for any reason.
            </p>
            <p>
              However, we retain the right, without prior notice, to revoke your
              registration, suspend access to specific parts of the Services, or
              terminate your overall access to the Services at our discretion.
              Additionally, we reserve the right to modify, suspend, or cease
              the Services altogether.
            </p>
            <p>
              Should you breach or pose a threat of breaching any provision
              outlined in these Terms of Use, not only will your right to use
              the Services be terminated, but we also reserve the right to seek
              injunctive relief to enforce these provisions. This does not limit
              our ability to pursue any other action or remedy for such
              breaches. If we succeed in such actions, you agree to reimburse us
              for all reasonable costs, expenses, and legal fees incurred.
            </p>
            <p>
              To safeguard the integrity of the Services, we reserve the right
              to block users from specific IP addresses from accessing and
              utilizing the Services at any time. Furthermore, we may request
              that you discontinue access or permanently delete certain content
              or information available through the Services.
            </p>
            <p className="termsOfUses-text-box">
              You have the option to discontinue using the Services whenever you
              choose. We reserve the discretion to alter or terminate the
              Services and to regulate user access. In the event of a violation
              of the Terms of Use, we are entitled to enforce these terms and
              may pursue additional actions against you as we see fit, at your
              expense.
            </p>

            <div className="privacy-content-text-heading">
              Termination or Stopping Use of the Services
            </div>

            <p>
              Please carefully review this section as it could significantly
              impact your legal rights, including the ability to bring a lawsuit
              in court and have a jury hear your claims.
            </p>
            <p>
              By using the Services, you agree irrevocably to: (a) waive any
              right to a trial in court before a judge or jury for any claim,
              action, or dispute with us or related in any way to your use of
              the Services or the interpretation, applicability, enforceability,
              or formation of these Terms of Use, including any claim that this
              agreement or any part of it is void or voidable (&quot;Claims&quot;); (b)
              have all Claims determined exclusively by final and binding
              arbitration in San Clara, California, before one arbitrator; and
              (c) ensure that the arbitrator does not have the authority to
              combine Claims from other Users or conduct proceedings as a class
              or collective action or grant relief to a group or class of Users
              in a single arbitration.
            </p>
            <p>
              The arbitration will be conducted by JAMS in accordance with its
              Comprehensive Arbitration Rules and Procedures, including the
              Expedited Procedures. Despite these rules, the proceeding will be
              governed by the laws of the State of California, Santa Clara. All
              parties must maintain the confidentiality of the arbitration
              proceeding and award, except as necessary for the arbitration
              hearing or as required by law or court order.
            </p>
            <p>
              The arbitrator, not any federal, state, or local court or agency,
              will have exclusive authority to resolve any Claims. However, this
              section does not prevent us from seeking relief for intellectual
              property rights enforcement, unfair competition, trade secret
              misappropriation, unauthorized access, fraud or computer fraud,
              and industrial espionage in court.
            </p>
            <p>
              The judgment on any arbitration award may be entered in any court
              with jurisdiction. In any arbitration related to these Terms of
              Use, the arbitrator will award the prevailing party, if any, the
              reasonable costs and attorneys&apos; fees incurred in the arbitration.
              If the arbitrator determines a party to be prevailing under
              circumstances where they won on some but not all claims, the
              arbitrator may award that party a proportionate share of the costs
              and attorneys&apos; fees.
            </p>

            <p className="termsOfUses-text-box">
              It&apos;s crucial to thoroughly read and comprehend this section. It
              outlines that by utilizing the Services, you consent to settle any
              dispute concerning the Services or these Terms of Use through
              binding arbitration, relinquishing your right to resolve disputes
              before a judge or jury. Additionally, you agree to arbitrate your
              claims individually, rather than as part of a class action.
            </p>
            <div className="privacy-content-text-heading">
              Services Auditing and Monitoring
            </div>

            <p>
              We maintain the right to inspect and monitor (either manually or
              through automated methods) the usage of the Services to ensure
              adherence to the Terms of Use and to enhance the delivery of the
              Services. Additionally, we may, though it is not obligatory,
              monitor the content on the Services by employing manual review or
              technical measures to assess, obstruct, filter, edit, or remove
              content. Users’ accounts may be suspended or terminated, or
              content may be deleted, edited, or removed by us, at our sole
              discretion, if we determine it to be unlawful, offensive, abusive,
              in violation of the Terms of Use or our other policies, or
              otherwise inappropriate or unacceptable. All decisions regarding
              enforcement are made solely by us, and we bear no liability or
              responsibility for the removal or deletion of any content.
            </p>

            <p>
              You recognize, consent to, and agree that we may access, retain,
              and disclose information regarding your use of the Services,
              including your communications and submitted content, if compelled
              to do so by law or if we have a good faith belief that such
              access, retention, or disclosure is reasonably necessary to: (i)
              adhere to legal procedures; (ii) enforce the Terms of Use; (iii)
              address claims of content submitted by you infringing on the
              rights of third parties; (iv) respond to your customer service
              inquiries; or (v) safeguard the rights, property, or personal
              safety of us, our users, and the general public.
            </p>

            <p className="termsOfUses-text-box">
              We reserve the right to monitor both the usage of the Services and
              the content accessible through them. By using the Services, you
              consent to our disclosure of information obtained through them
              when legally required or as mandated by law. Additionally, we
              retain the authority to modify or remove content, or suspend the
              use of the Services, based on the content submitted or available
              through them.
            </p>

            <div className="privacy-content-text-heading">General Terms</div>

            <ol className="alphabet-list">
              <li>
                Force Majeure. Neither party shall be held liable for any
                default resulting from force majeure, including circumstances
                beyond the reasonable control of the parties.
              </li>
              <li>
                <p>
                  Notices and Electronic Communications. We may deliver notices
                  to you through various channels, including email, regular
                  mail, telephone, or via the Services. By using the Services,
                  you consent to receiving communications from us electronically
                  and through the mentioned methods. Additionally, you agree to
                  the recording of telephone conversations with our
                  representatives or employees by engaging in such
                  conversations.
                </p>
                <p>
                  By providing your telephone number or texting JOIN to a
                  designated number, you subscribe to receive Vihara.ai Asset
                  Update Alerts and/or Auction.com Event Notifications via text
                  message. Your subscription authorizes us to send you text
                  messages with marketing messages, alerts, and updates about
                  properties you&apos;re interested in. You confirm ownership of the
                  provided number. You can cancel your subscription at any time
                  by texting &quot;STOP&quot; to a message from us or calling (916)
                  813-4649.
                </p>
                <p>
                  You also consent to our use of automatic telephone dialing
                  systems for calls and texts. You can revoke this consent at
                  any time through reasonable means.
                </p>
                <p>
                  If your carrier is T-Mobile, note that T-Mobile is not
                  responsible for delayed or undelivered messages.
                </p>
                <p>
                  You acknowledge that electronic communications from us fulfill
                  legal writing requirements, and you have the means to store
                  such communications.
                </p>
              </li>
              <li>
                Compliance with Applicable Laws: The Services are intended for
                individuals residing in the United States and are governed by US
                laws. You must comply with all applicable laws when accessing
                the Services. We reserve the right to restrict access to the
                Services at our discretion.
              </li>
              <li>
                Miscellaneous. These Terms of Use, along with any incorporated
                policies or terms, constitute the entire agreement between you
                and us regarding the Services. If any provision of these Terms
                of Use is found invalid or unenforceable, it will be enforced to
                the maximum extent permissible by law, and the remaining
                provisions will remain valid. Our failure to enforce any right
                or provision does not constitute a waiver unless expressly
                acknowledged in writing. You may not assign these Terms of Use
                without our consent, while we may assign them at our discretion.
              </li>
            </ol>

            <p className="termsOfUses-text-box">
              These are the standard legal terms that regulate your utilization
              of the Services.
            </p>

            <div className="privacy-content-text-heading">Contacting Us</div>

            <p>
              For questions about the Services, you should contact
              trisha@vihara.ai or call us at (916) 813-4649.
            </p>

            <p>These Terms of Use were last updated on May 4, 2024.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebSiteTermsOfUse;
