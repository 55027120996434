import './App.css';
import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import { setUser } from './features/user/userSlice';
import { setProperties } from './features/property/propertySlice';



const ContactUs = React.lazy(() => import('./components/ContactUs/ContactUs'));
const HomePage = React.lazy(() => import('./components/HomePage/HomePage'));
const Footer = React.lazy(() => import('./components/layouts/Footer/Footer'));
const Team = React.lazy(() => import('./components/MeetTheTeam/Team'));
const Glossary = React.lazy(() => import('./components/Resources/Glossary/Glossary'));
const Help = React.lazy(() => import('./components/Resources/Help/Help'));
const BuyingForclosure = React.lazy(() => import('./components/Resources/BuyingForClosure/BuyingForcosure'));
const BankOwned = React.lazy(() => import('./components/Resources/BankOwned/BankOwned'));
const About = React.lazy(() => import('./components/Company/AboutUS/About'));
const Career = React.lazy(() => import('./components/Company/Career/Career'));
const Diversity = React.lazy(() => import('./components/Company/Diversity/Diversity'));
const Blog = React.lazy(() => import('./components/Blog/Blog'));
import AuthSuccess from './components/AuthSucess/AuthSucess';
import Jobs from './components/Company/Jobs/Jobs';
import News from './components/News/News';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Bankowned from './components/Buy/BankOwned/Bankowned';
import OtpVerification from "./components/LoginSignUp/OtpVerification/OtpVerification"
import ShortSales from './components/Buy/ShortSales/ShortSales';
import Forclosure from './components/Buy/Forclosure/Forclosure';
import AdminPanel from './adminPanel/adminPanel/adminPanel';
import Login from './components/LoginSignUp/Login/Login';
import SignUp from './components/LoginSignUp/SignUp/SignUp';
import PrivacyStatments from './components/layouts/Footer/PrivacyPolicy/PrivacyStatments';
import ParticipationTerms from './components/layouts/Footer/ParticipationTerm/ParticipationTerms';
import PrivacyChoice from './components/layouts/Footer/PrivacyChoice/PrivacyChoice';
import WebSiteTermsOfUse from './components/layouts/Footer/WebSiteTermsOfUse/WebSiteTermsOfUse';
import Accesssibility from './components/layouts/Footer/Accessibility/Accesssibility';
import EventCalendar from './components/Calender/EventCalender/EventCalender'; 
import LearnMore from './components/HomePage/LearnMore/LearnMore';
import Buys from './components/Buy/Buys';
import ResetPassword from './components/LoginSignUp/Login/ResetPassword/ResetPassword';
import ConformationRequest from './components/LoginSignUp/Login/ResetPassword/ConformationRequest';
import ContactSupport from './components/layouts/Footer/ContactSupport/ContactSupport';
import PropertyDetail from './components/PropertyDetail/PropertyDetail';
import PrepearingOnlineAuction from './components/HomeComponents/MakingBiddingEasier/PrepareOnlineAuction/PrepearingOnlineAuction';
import SavedUpdates from './components/SavedforUpdates/SavedUpdates';
import UserLayout from './UserLayout/UserLayout';
import AdminLogin from './adminPanel/adminLogin/adminLogin';
import AdminLayout from './components/AdminLayout/AdminLayout';
import SellProperties from './components/SellProperties/SellProperties';
import Faqs from './components/Resources/Faqs/Faqs';
import Guide from './components/Resources/Guide/Guide';
import HomePageBlog from './components/HomePageBlog/HomePageBlog';
import BuyingAForclosure from './components/Resources/BuyingAForclosure/BuyingAForclosure';
import Privacy from './components/Company/Privacy/Privacy';
import BuyingBankOwned from './components/Resources/BuyingABankOwned/BuyingBankOwned';
import LearnMorePages from './components/LearnMorePages/LearnMorePages';
import LeaderShip from './components/LeaderShip/LeaderShip';
import UserPortal from './components/UserPortal/UserPortal';
import SetNewPassword from './components/LoginSignUp/Login/ResetPassword/SetNewPassword';
import LandingPage from "./components/LandingPage/LandingPage"
function App() {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  useEffect(() => {
    async function getAllProducts() {
      try {
        const response = await axios.get("https://viharabackend.onrender.com/api/v1/product/get");
        console.log(response);
        dispatch(setProperties(response.data.allProducts));
      } catch (error) {
        console.log(error);
      }
    }
    getAllProducts();
  }, [])
  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem("token");
      if (token) {
        try {
          const response = await axios.get('https://viharabackend.onrender.com/api/v1/user/get', {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });
          if (response.status === 200) {
            dispatch(setUser(response.data.user));
          } else {
            Cookies.remove('token');
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
    };

    if (isAuthenticated === false) fetchUserData();
  }, [dispatch]);

  const isScreenSmall = useMediaQuery("(max-width:840px)");
  return (
    <Router>
      <Suspense fallback={<Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '85vh',
        }}
      >
        <CircularProgress size={100} />
      </Box>}>
        <Routes>
          {/* User routes */}
          <Route path="/" element={<UserLayout />}>
            <Route index element={<HomePage />} />
            <Route path="team" element={<Team />} />
            <Route path="contact-us" element={<ContactUs />} />
            <Route path="glossary" element={<Glossary />} />
            <Route path="help" element={<Help />} />
            <Route path="buying-forclosure" element={<BuyingForclosure />} />
            <Route path="buying-bank-owned" element={<BankOwned />} />
            <Route path="about-us" element={<About />} />
            <Route path="career" element={<Jobs />} />
            <Route path="diversity" element={<Diversity />} />
            <Route path="login" element={<Login />} />
            <Route path="sign-up" element={<SignUp />} />
            <Route path="blog" element={<Blog />} />
            <Route path="privacy-statement" element={<PrivacyStatments />} />
            <Route path="participation-terms" element={<ParticipationTerms />} />
            <Route path="privacy-choice" element={<PrivacyChoice />} />
            <Route path="website-terms-of-use" element={<WebSiteTermsOfUse />} />
            <Route path="accessibility" element={<Accesssibility />} />
            <Route path="event-calender" element={<EventCalendar />} />
            <Route path="learn-More" element={<LearnMore />} />
            <Route path="auctions" element={<Buys />} />
            <Route path="reset-password" element={<ResetPassword />} />
            <Route path="request-send" element={<ConformationRequest />} />
            <Route path="contact-support" element={<ContactSupport />} />
            <Route path="Property-detail" element={<PropertyDetail />} />
            <Route path="prepearing_online_auction" element={<PrepearingOnlineAuction />} />
            <Route path="saved-updates" element={<SavedUpdates />} />
            <Route path="guide" element={<Guide />} />
            <Route path="faqs" element={<Faqs />} />
            <Route path="sell_properties" element={<SellProperties />} />
            <Route path="buying_a_forclosure" element={<BuyingAForclosure />} />
            <Route path="buying_a_bank_owned" element={<BuyingBankOwned />} />
            <Route path="blog/:blogname" element={<HomePageBlog />} />
            <Route path="Privacy" element={<Privacy />} />
            <Route path="learn-more/:type" element={<LearnMorePages />} />
            <Route path="leadership" element={<LeaderShip />} />
            <Route path="/company/news" element={<News />} />
            <Route path="/auth/success" element={<AuthSuccess />} />
            <Route path="/verify-otp" element={<OtpVerification />} />
            <Route path="portal/:tab" element={<UserPortal />} />
            <Route path="Property-detail/:id" element={<PropertyDetail/>}/>
            <Route path="/set-new-password/:token" element={<SetNewPassword/>}/>
            <Route path="LandingPage" element={<LandingPage />} />
          </Route>
          {/* Admin routes */}
          <Route path="admin" element={<AdminLayout />}>
            <Route path="login" element={<AdminLogin />} />
            <Route path="dashboard" element={<AdminPanel />} />
          </Route>
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
