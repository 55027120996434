import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBath, faBedPulse, faHouse, faHeart as Soliddheart } from '@fortawesome/free-solid-svg-icons';
import { faHeart as regularHeart } from '@fortawesome/free-regular-svg-icons';
import axios from "axios";
import { useEffect } from "react";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import "./Navbar.css"; // External CSS file
import { toast } from 'react-toastify';
import Breadcrumb from "../Breadcrumbs/Breadcrumbs";
import { useDispatch } from 'react-redux';
import { setUser } from '../../../features/user/userSlice';
import { useSelector } from "react-redux";
import PrintProperty from "../PrintProperty/PrintProperty";
const Navbar = ({product}) => {
  const [activeTab, setActiveTab] = useState("overview");
  const dispatch = useDispatch();
  const [isSaved, setIsSaved] = useState(true);
  const user = useSelector((state) => state.user.user);
  const handleTabClick = (tab) => {
    setActiveTab(tab);
    const section = document.getElementById(tab);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  useEffect(() => {
    if (user && user.savedProperties.length > 0 && user.savedProperties.find(productObject => productObject._id === product._id)) {
      setIsSaved(true);
    }
    else {
      setIsSaved(false);
    }
  }, [])
  const handleHeartClick = async (event) => {
    event.stopPropagation();
    if (!user) {
      toast.error("Plese sign in to save property");
      return;
    }
    const text = isSaved ? "rempve" : "save";
    const toasttext = isSaved ? "remove" : "save";
    try {
      console.log("hi")
      const response = await axios.put(`https://viharabackend.onrender.com/api/v1/user/${text}-property`, {
        user_id: user._id,
        product_id: product._id
      });
      dispatch(setUser(response.data.user));
      if (toasttext === "remove" && location.pathname !== "/saved-updates") {
        setIsSaved(false);
      }
      if (toasttext === "save") {
        setIsSaved(true);
      }
      toast.success(`Property ${toasttext} Successfully`);
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <div className="property-detail-navbar-container"> 
      <Breadcrumb product={product}  />
      <div className="property-detail-navbar">
        <div className="property-detail-navbar-left">
          <div
            className={`property-detail-navbar-item ${
              activeTab === "overview" ? "active" : ""
            }`}
            onClick={() => handleTabClick("overview")}
          >
            Overview
          </div>
          <div
            className={`property-detail-navbar-item ${
              activeTab === "propertyDetails" ? "active" : ""
            }`}
            onClick={() => handleTabClick("propertyDetails")}
          >
            Property Details
          </div>
          <div
            className={`property-detail-navbar-item ${
              activeTab === "rentalEstimate" ? "active" : ""
            }`}
            onClick={() => handleTabClick("rentalEstimate")}
          >
            Rental Estimate
          </div>
          {/* <div
            className={`property-detail-navbar-item ${
              activeTab === "propertyTax" ? "active" : ""
            }`}
            onClick={() => handleTabClick("propertyTax")}
          >
            Property Tax
          </div> */}
        </div>
        <div className="property-detail-navbar-right">
          <span  onClick={(event)=>handleHeartClick(event)} className="property-save-button" >
          <FontAwesomeIcon
           icon={isSaved ? Soliddheart : regularHeart}
            className="property-detail-navbar-icon"
          />
           Save
          </span>
          <PrintProperty product={product}/>
          {/* <FontAwesomeIcon
            icon={faShareSquare}
            className="property-detail-navbar-icon"
          />
          <FontAwesomeIcon
            icon={faPrint}
            className="property-detail-navbar-icon"
          /> */}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
