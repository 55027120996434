import React, { useEffect, useState } from "react";
import PropertyCard from "../PropertyCard/PropertyCard";
import "./PropertySample.css";
import PropTypes from "prop-types";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSearchParams } from "react-router-dom";
import {
  faPaperPlane,
  faFileExport,
  faPrint,
  faCaretDown,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
const states = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];
function PropertySample({
  selectedValues,
  products,
  onSelectState,
  state,
  city,
}) {
  let states = [];
  const propertiesforStates = useSelector((state) => state.property.Properties);
  propertiesforStates.forEach((item) => {
    if (!states.includes(item.state)) {
      states.push(item.state);
    }
  });
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isMostPopularOpen, setIsMostPopularOpen] = useState(false);
  const [isStateDropdownOpen, setIsStateDropdownOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [sortedBy, setSortedBy] = useState("Most Popular");
  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const handleMostPopularDropdown = () => {
    setIsMostPopularOpen(!isMostPopularOpen);
  };
  const handleStateDropdown = () => {
    setIsStateDropdownOpen(!isStateDropdownOpen);
  };
  const handleMenuItemClick = (option) => {
    const params = new URLSearchParams(searchParams);
    const currentIndex = selectedValues.indexOf(option);
    const newSelectedValues = [...selectedValues];
    if (option === "All States") {
      params.delete("states");
      setSearchParams(params);
      onSelectState([]);
      return;
    }
    if (currentIndex === -1) {
      newSelectedValues.push(option);
      params.append("states", option);
    } else {
      newSelectedValues.splice(currentIndex, 1);
      const allParams = params.getAll("states");
      params.delete("states");
      allParams.forEach((value) => {
        if (value !== option) {
          params.append("states", value);
        }
      });
    }
    setSearchParams(params);
    onSelectState(newSelectedValues);
  };
  const handleStateRemove = (option) => {
    const params = new URLSearchParams(searchParams);
    const currentIndex = selectedValues.indexOf(option);
    const newSelectedValues = [...selectedValues];
    newSelectedValues.splice(currentIndex, 1);
    const allParams = params.getAll("states");
    params.delete("states");
    allParams.forEach((value) => {
      if (value !== option) {
        params.append("states", value);
      }
    });
    setSearchParams(params);
    onSelectState(newSelectedValues);
  };
  if (propertiesforStates.length === 0) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress size={100} />
      </Box>
    );
  }
  const timeLeft = (endDate) => {
    return new Date(endDate) - new Date();
  };
  const sortByPriceLowToHigh = () => {
    setSortedBy("Price (Low to High)");
    products.sort((a, b) => a.startBid - b.startBid);
  };
  const sortByPriceHighToLow = () => {
    setSortedBy("Price (High to Low)");
    products.sort((a, b) => b.startBid - a.startBid);
  };
  const sortByTimeLeftLeastToMost = () => {
    setSortedBy("Price (Least To Most)");
    products.sort(
      (a, b) => new Date(a.auctionEndDate) - new Date(b.auctionEndDate)
    );
  };
  const sortByTimeLeftMostToLeast = () => {
    setSortedBy("Price (Most To Least)");
    products.sort(
      (a, b) => new Date(b.auctionEndDate) - new Date(a.auctionEndDate)
    );
    console.log(products);
  };
  const sortByMostPopular = () => {
    setSortedBy("Most Popular");
  };
  return (
    <>
      {products ? (
        <div className="property-sample">
          <div className="property-location">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d387199.51147032203!2d-74.30932647699741!3d40.69608170403378!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c2f9505bd01851%3A0xeb495aecf9816815!2sTiLT%20Museum!5e0!3m2!1sen!2sin!4v1718468874061!5m2!1sen!2sin"
              width="100%"
              height="600px"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <div
            className="property-cards"
            style={{
              height: products.length <= 2 && screenWidth >= 646 ? "455px" : "",
            }}
          >
            <div className="buy-navbar-extra-filters-container">
              <div className="buy-navbar-extra-filters-title-section">
                <h1>
                  Home and Real Estate Auctions in{" "}
                  <span>
                    {state ? (
                      <span style={{ color: "black" }}>
                        {state}
                        {city ? <>, {city}</> : <></>}
                      </span>
                    ) : (
                      <span onClick={handleStateDropdown}>
                        {selectedValues.length === 0
                          ? "All States"
                          : `${selectedValues.length} ${
                              selectedValues.length === 1 ? "State" : "States"
                            }`}{" "}
                        <FontAwesomeIcon
                          className="buy-navbar-extra-filters-icons"
                          icon={faCaretDown}
                        />{" "}
                      </span>
                    )}
                    {isStateDropdownOpen && (
                      <div className="state-filter-dropdown">
                        <ul className="state-filter-list">
                          <li
                            className="state-filter-item"
                            onClick={() => handleMenuItemClick("All States")}
                          >
                            <label className="state-checkbox-label">
                              <input
                                type="checkbox"
                                checked={
                                  selectedValues.length === 0 ? true : false
                                }
                                readOnly
                              />
                              All States
                            </label>
                          </li>
                          {states.map((state, index) => (
                            <li key={index} className="state-filter-item">
                              <label className="state-checkbox-label">
                                <input
                                  type="checkbox"
                                  onChange={() => handleMenuItemClick(state)}
                                  checked={selectedValues.indexOf(state) > -1}
                                  readOnly
                                />
                                {state}
                              </label>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </span>
                </h1>
                <p>
                  {products.length} Results, sorted by{" "}
                  <span onClick={handleMostPopularDropdown}>
                    {sortedBy}{" "}
                    <FontAwesomeIcon
                      className="buy-navbar-extra-filters-icons"
                      icon={faCaretDown}
                    />
                    {isMostPopularOpen && (
                      <div className="most-filter-dropdown">
                        <ul className="most-filter-list">
                          <li
                            onClick={sortByMostPopular}
                            className="most-filter-item"
                          >
                            <span>Most Popular</span>
                          </li>
                          <li
                            onClick={sortByPriceLowToHigh}
                            className="most-filter-item"
                          >
                            <span>Price (Low to High)</span>
                          </li>
                          <li
                            onClick={sortByPriceHighToLow}
                            className="most-filter-item"
                          >
                            <span>Price (High to Low)</span>
                          </li>
                          <li
                            onClick={sortByTimeLeftLeastToMost}
                            className="most-filter-item"
                          >
                            <span>Time Left (Least to Most)</span>
                          </li>
                          <li
                            onClick={sortByTimeLeftMostToLeast}
                            className="most-filter-item"
                          >
                            <span>Time Left (Most to Least)</span>
                          </li>
                        </ul>
                      </div>
                    )}
                  </span>
                </p>
              </div>
              <div className="buy-navbar-extra-filters-buttons">
                <button className="buy-navbar-extra-filters-special-promo-btn">
                  Special Promotions
                </button>
                <button className="buy-navbar-extra-filters-nearby-btn">
                  <FontAwesomeIcon icon={faPaperPlane} /> Nearby Properties
                </button>
                {/* <button className="buy-navbar-extra-filters-export-btn">
                  <FontAwesomeIcon icon={faFileExport} /> Export
                </button>
                <button className="buy-navbar-extra-filters-print-btn">
                  <FontAwesomeIcon icon={faPrint} /> Print
                </button> */}
              </div>
            </div>
            <div className="selected-states-container">
              {selectedValues.map((value, index) => (
                <div className="selected-states" key={index}>
                  {value}{" "}
                  <span>
                    <FontAwesomeIcon
                      onClick={() => handleStateRemove(value)}
                      icon={faXmark}
                    />
                  </span>
                </div>
              ))}
            </div>
            {products.length === 0 && (
              <div className="no-properties-container">
                <h3 className="no-properties-title">
                  Sorry, No Properties Found!
                </h3>
                <p className="no-properties-description">
                  We may not have any properties that match your search, but
                  it's always good to confirm by trying the search again. Here
                  are some helpful hints:
                </p>
                <ul className="no-properties-list">
                  <li>Be sure you spell correctly</li>
                  <li>
                    If you search by zip code, make sure you've entered all 5
                    digits
                  </li>
                  <li>
                    Try expanding your search to a larger geographic area (try
                    city, county, state or zip code)
                  </li>
                  <li>
                    Enter the MLS # or Hubzu Property ID to quickly find the
                    property you want
                  </li>
                  <li>
                    If you search by address, be sure you enter the full address
                    (street #, street name, city, state & zip)
                  </li>
                </ul>
              </div>
            )}
            {products.map((property, index) => (
              <PropertyCard
                key={index}
                Property={property}
                product={property}
              />
            ))}
          </div>
        </div>
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
}
export default PropertySample;
